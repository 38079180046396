import React, { FC, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/yachtRental.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { useTranslation } from "react-i18next";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const myRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("boat");
  const scrollToRef = () => {
    if (myRef.current) myRef.current.scrollIntoView({ behavior: "smooth" });
  };
  // General scroll to element function

  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col justify-center items-center space-y-8 sm:space-y-10 pb-5 lg:pb-2 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            {t("BOATTITLE", "長勝遊艇租賃服務正式展開啦！")}
          </h2>
          <span className="text-base  md:text-lg text-neutral-500 dark:text-neutral-400">
            {t(
              "BOATSUBTITLE",
              "超靚仔嘅歐洲豪華遊艇無論去西貢、南區和陽光玩遊戲又或者維港夜遊我們都能幫到你享受愉快的海上生活"
            )}
          </span>
          {/* <ButtonPrimary onClick={() => scrollToRef()} className="!mt-10">
          {t('BOATBU', '立即預約')}
            
          </ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <img className="w-full" src={imagePng} alt="Yacht Rental" />
        </div>
      </div>
      <div ref={myRef}></div>
    </div>
  );
};

export default SectionHero;
