import { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";

const useFetch = (url: string | null) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!url) {
      return;
    }
    fetchData(url);
  }, [url]);

  const fetchData = async (fetchUrl: string) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(fetchUrl);
      setData(res.data);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  const reFetch = async (newUrl: string) => {
    // fetchData(newUrl);
  };

  return { data, loading, error, reFetch };
};

export default useFetch;
