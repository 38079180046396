import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "tc",
    fallbackLng: "tc",
    supportedLngs: ["en", "tc", "sc"],
    debug: process.env.NODE_ENV === "development",
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["cookie", "localStorage"],
    },
    defaultNS: "common",
    ns: ["common"],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: true,
    },
    nsSeparator: ":", // Separates namespaces
    keySeparator: ".", // Separates keys within a namespace
  });

i18n.on("languageChanged", (lng) => {
  console.log("Language changed to:", lng);
});

i18n.on("loaded", (loaded) => {
  console.log("Loaded resources:", loaded);
});

i18n.on("failedLoading", (lng, ns, msg) => {
  console.error("Failed loading:", lng, ns, msg);
});

export default i18n;
