"use client";

import { useEffect, useState } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import moment from "moment";
import useWindowSize from "hooks/useWindowResize";
import { useTranslation } from "react-i18next";
import { enUS, zhCN, zhTW } from "date-fns/locale"; // import the necessary locales
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { subDays, startOfDay, endOfDay } from "date-fns";
export interface RentalCarDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  start?: any;
  end?: any;
  pickUpLocation?: string;
  returnLocation?: string;
  productPage?: boolean;
  setStart?: any;
  setEnd?: any;
  unavailableDates?: any[];
  changeDate?: any;
  updateStart?: Date;
  updateEnd?: Date;
  updateStartTime?: Date | null;
  updateEndTime?: Date | null;
  changeStartTime?: any;
  changeEndTime?: any;
  mobileModal?: any;
  isTimeUnavailable?: any;
  isDateDisabled?: any;
  maxDateDatepicker?: Date;
  minDateDatepicker?: Date;
  setMaxDateDatepicker?: any;
  setMinDateDatepicker?: any;
  boat?: boolean;
}

type MyLocales = {
  [key: string]: Locale;
};

const locales: MyLocales = {
  en: enUS,
  sc: zhCN,
  tc: zhTW,
};

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  pickUpLocation,
  returnLocation,
  unavailableDates,
  changeDate,
  updateStart,
  updateEnd,
  updateStartTime,
  updateEndTime,
  changeStartTime,
  changeEndTime,
  mobileModal,
  isTimeUnavailable,
  isDateDisabled,
  maxDateDatepicker,
  minDateDatepicker,
  setMaxDateDatepicker,
  setMinDateDatepicker,
  boat = false,
}) => {
  const [startDate, setStartDate] = useState<Date | null>();
  // const disabledDates = [new Date("2023/03/20"), new Date("2023/03/25")];
  const [endDate, setEndDate] = useState<Date | null>();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();
  useEffect(() => {
    setStartDate(updateStart);
  }, [updateStart]);
  useEffect(() => {
    setEndDate(updateEnd);
  }, [updateEnd]);
  useEffect(() => {
    if (isTimeUnavailable) setStartTime(updateStartTime);
  }, [updateStartTime]);
  useEffect(() => {
    if (isTimeUnavailable) setEndTime(updateEndTime);
  }, [updateEndTime]);
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const onChangeDate = (dates: [Date | any, Date | any]) => {
    const [starts, ends] = dates;
    setStartDate(starts);
    setEndDate(ends);
    if (changeDate) changeDate(starts, ends);
    if (ends == null) {
      if (startDate != null && starts.toString() === startDate.toString()) {
        setStartDate(null);
        setEndDate(null);
        setMaxDateDatepicker(null);
        setMinDateDatepicker(() => {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
          return tomorrow;
        });
      } else if (unavailableDates && unavailableDates.length > 0) {
        let i = 0;
        for (const date of unavailableDates) {
          if (moment(new Date(date.pickUpDate)).isAfter(new Date(starts))) {
            setMaxDateDatepicker(new Date(date.pickUpDate));
            if (i > 0)
              setMinDateDatepicker(
                new Date(unavailableDates[i - 1].returnDate)
              );
            break;
          }
          i++;
        }
      }
    } else if (starts.toString() !== ends.toString()) {
      setEndDate(ends);
    }
  };

  // const handleSearch = () => {
  //   dispatch({
  //     type: "NEW_SEARCH",
  //     payload: { startDate, endDate, pickUpLocation, returnLocation },
  //   });
  //   navigate("/search", {
  //     state: { startDate, endDate, pickUpLocation, returnLocation },
  //   });
  // };

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const TimeInput = () => (
    <div className="flex max-h-[300px] mt-2 mb-2">
      <div className="timepick w-6/12 mr-2 relative">
        <h5 className="mb-2 text-center">
          {boat
            ? t("common:BOATPICKUPTIME", "上船時間")
            : t("common:PICKUPTIME", "取車時間")}
        </h5>
        <DatePicker
          className="text-center flex-1 cursor-pointer border-primary-700 text-[17px]"
          selected={startTime}
          onChange={(date) => {
            setStartTime(date);
            if (changeStartTime) changeStartTime(date);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={t("common:TIME", "時間") || "Time"}
          dateFormat="h:mm aa"
          filterTime={(time) =>
            isTimeUnavailable ? !isTimeUnavailable(time, "pickup") : true
          }
          onFocus={(e) => e.target.blur()}
          minTime={setHours(setMinutes(new Date(), 0), 10)}
          maxTime={setHours(setMinutes(new Date(), 0), 20)}
          // inline
        />
      </div>
      <div className="timepick w-6/12 relative">
        <h5 className="mb-2 text-center">
          {boat
            ? t("common:BOATRETURNTIME", "落船時間")
            : t("common:RETURNTIME", "還車時間")}
        </h5>
        <DatePicker
          className="flex-1 py-2 text-center cursor-pointer  border-primary-700 text-[17px]"
          selected={endTime}
          onChange={(date) => {
            setEndTime(date);
            if (changeEndTime) changeEndTime(date);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption={t("common:TIME", "時間") || "Time"}
          dateFormat="h:mm aa"
          filterTime={(time) =>
            isTimeUnavailable ? !isTimeUnavailable(time, "return") : true
          }
          onFocus={(e) => e.target.blur()}
          minTime={setHours(setMinutes(new Date(), 0), 10)}
          maxTime={setHours(setMinutes(new Date(), 0), 20)}
          // inline
        />
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`overflow-hidden ${
          !mobileModal &&
          "rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5"
        } bg-white dark:bg-neutral-800 p-8`}
      >
        <DatePicker
          locale={locales[currentLanguage]}
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDateDatepicker}
          maxDate={maxDateDatepicker}
          selectsRange
          monthsShown={WIN_WIDTH < 768 ? 1 : 2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
          // excludeDates={disabledDates}
          focusSelectedMonth={true}
          excludeDateIntervals={
            unavailableDates &&
            unavailableDates
              .map((date) => {
                let firstDate = date?.pickUpDate;
                let endDate = date?.returnDate;
                if (new Date(date?.pickUpDate).getHours() > 9) {
                  firstDate = endOfDay(firstDate);
                } else {
                  firstDate = startOfDay(firstDate);
                }
                if (new Date(date?.returnDate).getHours() < 20) {
                  endDate = endOfDay(subDays(date?.returnDate, 1));
                } else {
                  endDate = endOfDay(endDate);
                }
                if (endDate > firstDate) {
                  return {
                    start: new Date(firstDate),
                    end: new Date(endDate),
                  };
                } else {
                  return null;
                }
              })
              .filter((date) => date !== null)
              .map((date) => date as { start: Date; end: Date })
          }
          // filterDate={(date) => !isDateDisabled(date)}
        />
        <TimeInput />
        <span
          className="block cursor-pointer underline w-fit"
          onClick={() => {
            setMaxDateDatepicker(null);
            setMinDateDatepicker(() => {
              const tomorrow = new Date();
              tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
              return tomorrow;
            });
            setEndDate(null);
            setStartDate(null);
            if (changeDate) changeDate(null, null);
          }}
        >
          {t("CLEAR", { defaultValue: "Clear" })}
        </span>
      </div>
    </>
  );
};

export default RentalCarDatesRangeInput;
