import { url } from "inspector";
import React, { FC } from "react";

export interface BgGlassmorphismProps {
  className?: string;
  images?: string;
}

const BgGlassmorphism: FC<BgGlassmorphismProps> = ({
  images,
  className = "absolute inset-x-0  w-full h-[600px] w-full md:h-[700px] lg:h-[800px] xl:h-[900px]  min-h-0   flex overflow-hidden z-0",
}) => {
  return (
    <div
      className={`nc-BgGlassmorphism ${className} bg-cover bg-center absolute inset-0`}
      style={{ backgroundImage: `url(${images})` }}
      data-nc-id="BgGlassmorphism"
    >
      <div
        className="nc-BgGlassmorphism-overlay absolute inset-0"
        style={{
          opacity: 0.67,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          backgroundImage:
            "linear-gradient(90deg, #101218 100%, var( --e-global-color-astglobalcolor0 ) 0%)",
        }}
      />
    </div>
  );
};

export default BgGlassmorphism;

export const Bg2Glassmorphism: FC<BgGlassmorphismProps> = ({
  className = "absolute inset-x-0 md:top-10 xl:top-40 min-h-0 pl-20 py-24 flex overflow-hidden z-0",
}) => {
  return (
    <div
      className={`nc-BgGlassmorphism ${className}`}
      data-nc-id="BgGlassmorphism"
    >
      <span className="block bg-[#ef233c] w-72 h-72 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96"></span>
      <span className="block bg-[#04868b] w-72 h-72 -ml-20 mt-40 rounded-full mix-blend-multiply filter blur-3xl opacity-10 lg:w-96 lg:h-96 nc-animation-delay-2000"></span>
    </div>
  );
};
