import { ReactNode } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";

const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  // const navigate = useNavigate();
  // const thisPathname = useLocation().pathname;
  // const [searchParams] = useSearchParams();
  // const modal = searchParams?.get("modal");

  // const handleCloseModalImageGallery = () => {
  //   let params = new URLSearchParams(document.location.search);
  //   params.delete("modal");
  //   navigate(`${thisPathname}/?${params.toString()}`);
  // };

  // const getImageGalleryListing = () => {
  //   if (thisPathname?.includes("/car")) {
  //     return listingCarImageGallery;
  //   }

  //   return [];
  // };

  return (
    <div className="ListingDetailPage">
      {/* <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      /> */}

      <div className="container ListingDetailPage__content pb-10">
        {children}
      </div>

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};

export default DetailPagetLayout;
