import { avatarColors } from "contains/contants";
import { FC } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";

export interface AvatarProps {
  className?: string;
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string | null;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
  mobile?: boolean;
}

const Avatar: FC<AvatarProps> = ({
  className = "",
  containerClassName = "dark:ring-neutral-900",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl, // Set the default value to null
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 -top-0.5 -right-0.5",
  mobile,
}) => {
  const baseImgUrl = "http://localhost:8800/uploads/images/";
  const name = userName || "P";
  const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center uppercase font-semibold ${radius} ${sizeClass} ${containerClassName} ${className} ${
        mobile ? "text-neutral-0 dark:text-neutral-300/90 mt-[7px]" : ""
      } `}
      style={{
        backgroundColor: !userName ? undefined : _setBgColor(name),
      }}
    >
      {/* {url && (
        <img
          className={`absolute inset-0 w-full h-full object-cover ${radius}`}
          src={url}
          alt={name}
        />
      )} */}
      {/* {mobile ? (
        <UserCircleIcon />
      ) : (
        imgUrl && (
          <img
            className={`absolute inset-0 w-full h-full object-cover ${radius}`}
            src={url}
            alt={name}
          />
        )
      )} */}
      {userName ? (
        <span className="wil-avatar__name text-white">{name[0]}</span>
      ) : (
        <UserCircleIcon
          // className="w-7 h-7 text-primary-6000 dark:text-white"
          className="w-7 h-7 "
          aria-hidden="true"
        />
      )}

      {hasChecked && (
        <span
          className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
        >
          <i className="las la-check"></i>
        </span>
      )}
    </div>
  );
};

export default Avatar;
