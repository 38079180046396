import CarCard from "components/CarCard/CarCard";
import { AuthContext } from "context/authContext";
import { useContext, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading/loading";
import axiosInstance from "../../utils/axiosInstance";
import { CarDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AccountSavelists = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [data, setData] = useState<[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation("account");

  useEffect(() => {
    const fetchCarList = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(`/api/v1/users/getcar/${user._id}`);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (user) fetchCarList();
  }, [user]);

  const itemVariants = (index: number) => ({
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: index * 0.1, // 0.1 seconds delay per item
      },
    },
  });

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">
            {t("SAVELIST", { defaultValue: "儲存清單" })}
          </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {loading ? (
          <Loading className="min-h-[50rem]" />
        ) : data?.length > 0 ? (
          <div>
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {data.map((car: CarDataType, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  animate="visible"
                  variants={itemVariants(index)}
                >
                  <CarCard key={car._id} data={car} wishList={true} />
                </motion.div>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="w-full text-center">
              <p className="text-3xl pb-5 mb-5">
                <div className="w-24 h-24 items-center rounded-full mx-auto flex justify-center mb-5 bg-gray-200 text-center text-gray-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-12 h-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                    />
                  </svg>
                </div>
                <div className="text-gray-400 font-bold">
                  {t("NOCAR", { defaultValue: "找不到車輛" })}
                </div>
              </p>
              <ButtonPrimary
                onClick={() => navigate("/car-rental")}
                className="px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
              >
                {t("ADDCAR", { defaultValue: "新增車輛" })}
              </ButtonPrimary>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {user ? (
        <div>
          <CommonLayout>{renderSection1()}</CommonLayout>
        </div>
      ) : (
        <section className="flex items-center h-full sm:p-16 dark:bg-gray-900 dark:text-gray-100">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="w-40 h-40 dark:text-gray-600"
            >
              <path
                fill="currentColor"
                d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z"
              ></path>
              <rect
                width="176"
                height="32"
                x="168"
                y="320"
                fill="currentColor"
              ></rect>
              <polygon
                fill="currentColor"
                points="210.63 228.042 186.588 206.671 207.958 182.63 184.042 161.37 162.671 185.412 138.63 164.042 117.37 187.958 141.412 209.329 120.042 233.37 143.958 254.63 165.329 230.588 189.37 251.958 210.63 228.042"
              ></polygon>
              <polygon
                fill="currentColor"
                points="383.958 182.63 360.042 161.37 338.671 185.412 314.63 164.042 293.37 187.958 317.412 209.329 296.042 233.37 319.958 254.63 341.329 230.588 365.37 251.958 386.63 228.042 362.588 206.671 383.958 182.63"
              ></polygon>
            </svg>
            <p className="text-3xl">
              {t("LOGINAGAIN", { defaultValue: "請重新登入" })}!
            </p>
            <button
              onClick={() => navigate("/Login")}
              className="px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
            >
              {t("LOGINNOW", { defaultValue: "立即登入" })}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default AccountSavelists;
