import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, useContext, useEffect, useState } from "react";
import heroRightImage from "images/hero-right-car.png";
import SectionGridFilterCard from "containers/ListingCarPage/SectionGridFilterCard";
import useFetch from "hooks/useFetch";
import SectionVideos from "containers/PageHome/SectionVideos";
import Step from "components/StepSection/StepSection";
import { AuthContext } from "context/authContext";
import axiosInstance from "../utils/axiosInstance";
import { motion } from "framer-motion";
import { ContactForm } from "./contact";
import homeCover from "../images/goodwaycar-hkrent.jpeg";

export interface ListingCarPageProps {
  className?: string;
}

const HomePage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { user } = useContext(AuthContext);
  const { data, loading } = useFetch("/api/v1/cars");
  const [wishlists, setWishList] = useState([]);

  useEffect(() => {
    const fetchWishList = async () => {
      try {
        const res = await axiosInstance.get(
          `/api/v1/users/getcarid/${user._id}`
        );
        setWishList(res.data.carList);
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      fetchWishList();
    }
  }, [user]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className={`nc-ListingCarPage ${className} relative lg:static `}
      data-nc-id="ListingCarPage"
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <BgGlassmorphism images={homeCover} />

      <div className="container relative">
        {/* SECTION 1 */}

        <div className="container relative">
          <SectionHeroArchivePage
            rightImage={heroRightImage}
            currentPage="Cars"
            currentTab="Cars"
            className="  pt-10 pb-24 lg:pb-28 lg:pt-16 a "
          />
        </div>

        {/* SECTION 2 */}

        <SectionGridFilterCard
          data={data}
          className="pb-24 pt-10 lg:pt-0 lg:pb-28"
          loading={loading}
          wishList={wishlists}
          itemsPerPage={20}
        />
      </div>
      {/* SECTION 3 */}
      <Step />
      <div className="container relative">
        {/* SECTION 4 */}
        <SectionVideos />

        {/* SECTION 5 */}

        <ContactForm />
      </div>
    </motion.div>
  );
};

export default HomePage;
