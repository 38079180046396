import { Tab } from "@headlessui/react";
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import useFetch from "hooks/useFetch";
import { FetchProductDetail } from "./product";
import RentalCarDatesRangeInput from "components/HeroSearchForm/(car-search-form)/RentalCarDatesRangeInput";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { useCheckoutState } from "hooks/useCheckoutState";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "components/Loading/loading";
import axiosInstance from "../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { AuthContext } from "context/authContext";
import ErrorPage from "./404";
import { motion } from "framer-motion";
import AirWallex from "./airwallex";
import { format, getHours, getMinutes, isAfter } from "date-fns";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import Select from "shared/Select/Select";
import { Helmet } from "react-helmet";
import { CountrySelector } from "react-international-phone";
import "react-international-phone/style.css";

export interface CheckOutPagePageMainProps {
  className?: string;
}

export interface UserDataType {
  engName?: string;
  gender?: string;
  email?: string;
  contactNo?: string;
  address?: string;
  register?: boolean;
  password: "";
  confirmPassword: "";
  carCoverCheck?: boolean;
}

export interface FetchUserDetail {
  userData?: UserDataType | any;
  loading?: boolean;
  error?: any;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  let coverDefault: any = false;
  const { state } = useLocation();
  const [checkoutState, setCheckoutState] = useCheckoutState();
  const {
    startDate,
    endDate,
    pickUpLocation,
    returnLocation,
    productId,
    isBoat,
  } = checkoutState || {
    startDate: null,
    endDate: null,
    pickUpLocation: null,
    returnLocation: null,
    productId: null,
  };
  console.log(productId);
  const [boatPlan, setBoatPlan] = useState(1);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openRegister, setOpenRegister] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [rentalDays, setRentalDays] = useState<number>(0);
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [unavailableDates, setUnavailableDates] = useState<any[]>([]);
  const [pickUp, setPickup] = useState<string>("");
  const [returnCar, setReturnCar] = useState<string>("");
  const [pickUpDeliveryFee, setPickUpDeliveryFee] = useState<number>(0);
  const [returnDeliveryFee, setReturnDeliveryFee] = useState<number>(0);
  const [startPayment, setStartPayment] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("card");
  const [intentId, setIntentId] = useState("");
  const [extraFee, setExtraFee] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [maxDateDatepicker, setMaxDateDatepicker] = useState<any>(null);
  const [showPickupTimeWarning, setShowPickupTimeWarning] = useState(false);
  const [showReturnTimeWarning, setShowReturnTimeWarning] = useState(false);
  const [countryPhone, setCountryPhone] = useState("hk");
  const [extraCoverPrice, setExtraCoverPrice] = useState(0);
  const [countryCode, setCountryCode] = useState("852");
  const [minDateDatepicker, setMinDateDatepicker] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
    return tomorrow;
  });
  const [isLoading, setIsLoading] = useState(false);
  const { data, loading, error }: FetchProductDetail = useFetch(
    `/api/v1/cars/${productId}`
  );
  const [userData, setUserData] = useState<any>();
  const [bookingInformation, setBookingInformation] = useState();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation("checkout");

  const unavailableDatesFetch: FetchProductDetail = useFetch(
    `/api/v1/cars/status/${productId}`
  );

  // console.log(res);
  useEffect(() => {
    if (unavailableDatesFetch.data) {
      const updateUnavailableDates =
        unavailableDatesFetch.data?.length > 0
          ? unavailableDatesFetch.data?.map((date: any) => {
              const pickUpDate = moment(date.pickUpDate).tz("Asia/Hong_Kong");
              const returnDate = moment(date.returnDate).tz("Asia/Hong_Kong");
              // if (pickUpDate.hour() === 10) {
              //   pickUpDate.startOf("day"); // Set pick-up time to the start of the day
              // }
              // if (returnDate.hour() === 20) {
              //   returnDate.endOf("day"); // Set return time to the end of the day
              // }
              return {
                pickUpDate: pickUpDate.toDate(),
                returnDate: returnDate.toDate(),
              };
            })
          : unavailableDatesFetch.data;
      setUnavailableDates(updateUnavailableDates);
    }
  }, [unavailableDatesFetch.data]);

  useEffect(() => {
    if (pickUpLocation) {
      setPickup(pickUpLocation);
      formik.setFieldValue("pickupLocation", pickUpLocation);
    }
  }, [pickUpLocation]);

  useEffect(() => {
    if (returnLocation) {
      setReturnCar(returnLocation);
      formik.setFieldValue("returnLocation", returnLocation);
    }
  }, [returnLocation]);

  useEffect(() => {
    if (unavailableDates && unavailableDates.length > 0 && start) {
      let i = 0;
      for (const date of unavailableDates) {
        if (isAfter(new Date(date.pickUpDate), start)) {
          setMaxDateDatepicker(new Date(date.pickUpDate));
          if (i > 0)
            setMinDateDatepicker(new Date(unavailableDates[i - 1].returnDate));
          break;
        }
        i++;
      }
    }
    // if (start && isBoat) {
    //   setEnd(start);
    // }
  }, [unavailableDates, start]);

  const mergeDateAndTime = (
    date: Date | null,
    time: Date | null
  ): Date | null => {
    if (!date || !time) return null;

    const merged = new Date(date);
    merged.setHours(time.getHours(), time.getMinutes(), 0, 0);
    return merged;
  };
  const calculateDateDifferenceWithHours = (
    start: Date | null,
    end: Date | null
  ): { days: number; hours: number } => {
    if (!start || !end) {
      return { days: 0, hours: 0 };
    }

    const diffInMilliseconds = end.getTime() - start.getTime();
    let diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    let days = Math.floor(diffInHours / 24);
    let hours = Math.floor(diffInHours % 24);

    // If the end time is earlier than the start time, treat it as a full day.
    if (
      end.getHours() < start.getHours() ||
      (end.getHours() === start.getHours() &&
        end.getMinutes() < start.getMinutes())
    ) {
      hours = 0;
      days += 1;
    }

    return { days, hours };
  };
  const calculatePrice = useCallback(
    (
      starts: Date | null,
      ends: Date | null,
      startTime: Date | null,
      endTime: Date | null,
      pricePlan = boatPlan
    ) => {
      if (!data?.isBoat) {
        if (data?.price?.price[1]) {
          const startMerged = mergeDateAndTime(starts, startTime);
          const endMerged = mergeDateAndTime(ends, endTime);
          setStart(startMerged);
          setEnd(endMerged);
          const { days, hours } = calculateDateDifferenceWithHours(
            startMerged,
            endMerged
          );
          let number = days;
          let extraCharge = 0;
          // Calculate extra charge based on hours
          if (days !== 0 && hours <= 3 && hours > 0) {
            extraCharge += 150;
          } else if (days !== 0 && hours <= 12 && hours > 0) {
            extraCharge += Math.round(data?.price?.price[1] / 2);
          } else if (days !== 0 && hours > 12 && hours > 0) {
            extraCharge += Math.round(data?.price?.price[1]);
          }
          if (days !== 0 && hours >= 1) {
            setExtraCoverPrice(data?.price?.carCover || 0);
          } else {
            setExtraCoverPrice(0);
          }
          setRentalDays(number);
          setExtraFee(extraCharge || 0);
          if (number < 1) {
            setRentalDays(1);
            setExtraFee(0);
            number = 1;
          }
          if (number > 89 && data?.price?.price?.["90"]) {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["90"] / 90) * number + (extraCharge || 0)
              )
            );
          } else if (number > 59 && data?.price?.price?.["60"]) {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["60"] / 60) * number + (extraCharge || 0)
              )
            );
          } else if (number < 31) {
            setTotalPrice(data?.price?.price?.[number] + (extraCharge || 0));
          } else {
            setTotalPrice(
              Math.round(
                (data?.price?.price?.["30"] / 30) * number + (extraCharge || 0)
              )
            );
          }
        }
      } else {
        if (data?.price?.price[1]) {
          const startMerged = mergeDateAndTime(starts, startTime);
          const endMerged = mergeDateAndTime(ends, endTime);
          setStart(startMerged);
          setEnd(endMerged);
          const { days, hours } = calculateDateDifferenceWithHours(
            startMerged,
            endMerged
          );
          let number = days;
          if (number < 1) {
            setRentalDays(1);
            if (hours == 0) {
              setTotalPrice(data?.price?.price?.[3]);
              setBoatPlan(3);
            } else if (hours <= 8) {
              setTotalPrice(data?.price?.price?.[1]);
              setBoatPlan(1);
            } else if (hours <= 12) {
              setTotalPrice(data?.price?.price?.[2]);
              setBoatPlan(2);
            } else if (hours < 24) {
              setTotalPrice(data?.price?.price?.[3]);
              setBoatPlan(3);
            } else {
              setTotalPrice(data?.price?.price?.[3] * 2);
              setBoatPlan(4);
            }
          } else {
            setRentalDays(number);
            setTotalPrice(data?.price?.price?.[3] * number);
            setBoatPlan(3);
          }
        }
      }
    },
    [data?.price?.price, calculateDateDifferenceWithHours, data?.isBoat]
  );

  useEffect(() => {
    if (startDate) {
      setStart(startDate);
      formik.setFieldValue("startDate", startDate);
    }
    if (endDate) {
      setEnd(endDate);
      formik.setFieldValue("endDate", endDate);
    }

    if (startDate && endDate && startTime && endTime && data) {
      calculatePrice(startDate, endDate, startTime, endTime);
    }
    if (showPickupTimeWarning) {
      setShowPickupTimeWarning(false);
    }
    if (showReturnTimeWarning) {
      setShowReturnTimeWarning(false);
    }
  }, [startDate, endDate, data]);

  useEffect(() => {
    setStartTime(state?.startTime);
    setEndTime(state?.endTime);
    if (state?.boatPlan) setBoatPlan(boatPlan);
  }, [state]);

  const isTimeUnavailable = (time: Date, type: string) => {
    // Format both dates to yyyy-mm-dd format to compare only dates, not times
    if (start && end) {
      const selectedStartDate = format(start, "yyyy-MM-dd");
      const selectedEndDate = format(end, "yyyy-MM-dd");
      for (let i = 0; i < unavailableDates.length; i++) {
        let unavailableStartDate = format(
          new Date(unavailableDates[i].pickUpDate),
          "yyyy-MM-dd"
        );
        let unavailableEndDate = format(
          new Date(unavailableDates[i].returnDate),
          "yyyy-MM-dd"
        );

        // Check if the selected range overlaps with the unavailable range
        if (type === "pickup" && selectedStartDate == unavailableEndDate) {
          const hour = getHours(time);
          const minutes = getMinutes(time);
          // Adjust the unavailable hour by considering timezone offset (8 hours for Asia/Hong_Kong)
          const unavailableHour = getHours(
            new Date(unavailableDates[i].returnDate)
          );
          const unavailableMinutes = getMinutes(
            new Date(unavailableDates[i].returnDate)
          );
          // If the selected time is the same or after the unavailable time, then this time slot should be unavailable
          if (
            hour < unavailableHour ||
            (hour === unavailableHour && minutes < unavailableMinutes)
          ) {
            return true;
          }
        } else if (
          type === "return" &&
          selectedEndDate == unavailableStartDate
        ) {
          const hour = getHours(time);
          const minutes = getMinutes(time);
          // Adjust the unavailable hour by considering timezone offset (8 hours for Asia/Hong_Kong)
          const unavailableHour = getHours(
            new Date(unavailableDates[i].pickUpDate)
          );
          const unavailableMinutes = getMinutes(
            new Date(unavailableDates[i].pickUpDate)
          );
          // If the selected time is the same or after the unavailable time, then this time slot should be unavailable
          if (
            hour > unavailableHour ||
            (hour === unavailableHour && minutes > unavailableMinutes)
          ) {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  };

  const validationSchema = Yup.object(
    openRegister
      ? {
          pickupLocation: Yup.string().required(
            `${t("PICKUPLOCATIONREQUIRED", "請選擇取車地點")}`
          ),
          returnLocation: Yup.string().required(
            `${t("RETURNLOCATIONREQUIRED", "請選擇還車地點")}`
          ),
          startDate: Yup.string().required(
            `${t("PICKUPDATEREQUIRED", "請選擇取車日期")}`
          ),
          endDate: Yup.string().required(
            `${t("RETURNDATEREQUIRED", "請選擇還車日期")}`
          ),
          engName: Yup.string().required(`${t("NAMEREQUIRED", "請輸入名字")}`),
          gender: Yup.string().required("請揀選性別"),
          email: Yup.string()
            .email(`${t("INVALIDEMAILFORMAT", "電子郵件格式無效")}`)
            .required(`${t("EMAILREQUIRED", "請輸入電子郵件")}`),
          confirmEmail: Yup.string()
            .oneOf(
              [Yup.ref("email"), undefined],
              `${t("EMAILMUSTMATCH", "兩次輸入的電子郵件必須相同")}`
            )
            .required(`${t("CONFIRMEMAILREQUIRED", "請輸入確認電子郵件")}`),
          phone: Yup.string().required(
            `${t("PHONEREQUIRED", "請輸入電話號碼")}`
          ),
          password: Yup.string()
            .min(6, `${t("ENGREQUIREDNO", "密碼必須至少有6個字符")}`)
            .required(`${t("REQUIRED", "必填")}`),
          confirmPassword: Yup.string()
            .oneOf(
              [Yup.ref("password"), undefined],
              `${t("PASSWORDMATH", "兩次輸入的密碼必須相同")}`
            )
            .required(`${t("REQUIRED", "必填")}`),
        }
      : {
          pickupLocation: Yup.string().required(
            `${t("PICKUPLOCATIONREQUIRED", "請選擇取車地點")}`
          ),
          returnLocation: Yup.string().required(
            `${t("RETURNLOCATIONREQUIRED", "請選擇還車地點")}`
          ),
          startDate: Yup.string().required(
            `${t("PICKUPDATEREQUIRED", "請選擇取車日期")}`
          ),
          endDate: Yup.string().required(
            `${t("RETURNDATEREQUIRED", "請選擇還車日期")}`
          ),
          engName: Yup.string().required(`${t("NAMEREQUIRED", "請輸入名字")}`),
          gender: Yup.string().required(`${t("GENDERREQUIRED", "請揀選性別")}`),
          email: Yup.string()
            .email(`${t("INVALIDEMAILFORMAT", "電子郵件格式無效")}`)
            .required(`${t("EMAILREQUIRED", "請輸入電子郵件")}`),
          confirmEmail: Yup.string()
            .oneOf(
              [Yup.ref("email"), undefined],
              `${t("EMAILMUSTMATCH", "兩次輸入的電子郵件必須相同")}`
            )
            .required(`${t("CONFIRMEMAILREQUIRED", "請輸入確認電子郵件")}`),
          phone: Yup.string().required(
            `${t("PHONEREQUIRED", "請輸入電話號碼")}`
          ),
        }
  );

  const currency = "HKD";
  const bookingTime = useRef<HTMLDivElement>(null);
  const renderRadio = (
    name: string,
    id: string,
    label: string,
    value: number,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center cursor-pointer">
        <input
          checked={boatPlan === value}
          id={id + name}
          name={name}
          type="radio"
          onChange={() => {
            setBoatPlan(value);
            calculatePrice(start, end, startTime, endTime, value);
          }}
          className="focus:ring-gray-500 h-6 w-6 text-gray-500 border-neutral-300 !checked:bg-gray-500 bg-transparent cursor-pointer"
          disabled
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300 cursor-pointer"
        >
          {label}
        </label>
      </div>
    );
  };

  const handlePayWithAirwallex = async ({
    bookingId,
  }: {
    bookingId: string;
  }) => {
    try {
      const response = await axiosInstance.post(
        "/api/v1/payments/create-payment-intent",
        {
          totalPrice:
            formik?.values?.carCoverCheck && data?.price?.carCover > 0
              ? totalPrice +
                (data?.price?.carCover * rentalDays + extraCoverPrice || 0) +
                pickUpDeliveryFee +
                returnDeliveryFee
              : totalPrice + pickUpDeliveryFee + returnDeliveryFee,
          currency,
          bookingId,
        }
      );
      if (response?.status == 201 || response?.status == 200) {
        setIntentId(response.data?.id);
        setClientSecret(response.data?.client_secret);
        setStartPayment(true);
      }
    } catch (error) {
      setIsLoading(false);
      setAlert({
        show: true,
        message: `${error}`,
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
      console.error("Error in creating payment intent:", error);
      // Handle error in creating payment intent
      // Display an error message to user, for instance.
    }
  };

  const firstErrorRef = useRef(null);

  const locationsMap: { [key: string]: string } = {
    "Our Shop (Cheung Sha Wan)": "csw",
    "長沙灣興華街38號地下（ 順寧道461號側入）（长沙湾）": "csw",
    "長沙灣興華街38號地下（ 順寧道461號側入）（長沙灣）": "csw",
    "Hong Kong Island (+$500)": "hki",
    "港岛送车上门 (+$500)": "hki",
    "港島送車上門 (+$500)": "hki",
    "Kowloon (+$300)": "kowloon",
    "九龙送车上门 (+$300)": "kowloon",
    "九龍送車上門 (+$300)": "kowloon",
    "New Territories (+$500)": "nt",
    "新界送车上门 (+$500)": "nt",
    "新界送車上門 (+$500)": "nt",
    "Hong Kong International Airport (+$700)": "airport",
    "机场送车上门 (+$700)": "airport",
    "機場送車上門 (+$700)": "airport",
  };

  const boatlocationsMap: { [key: string]: string } = {
    "Kwun Tong": "kwuntong",
    觀塘: "kwuntong",
    观塘: "kwuntong",
    Central: "central",
    中環: "central",
    中环: "central",
    "Tsim Sha Tsui": "tst",
    尖沙咀: "tst",
    "Sai Kung": "saikung",
    西貢: "saikung",
    西贡: "saikung",
  };
  // Formik form setup
  const formik = useFormik({
    initialValues: {
      pickupLocation: "",
      returnLocation: "",
      startDate: "",
      endDate: "",
      engName: user ? user.engName : "",
      gender: user ? user?.gender : "",
      email: user ? user.email : "",
      confirmEmail: user ? user.email : "",
      phone: user ? user.phone : "",
      password: "",
      confirmPassword: "",
      register: false,
      carCoverCheck: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setShowPickupTimeWarning(!startTime);
        setShowReturnTimeWarning(!endTime);
        if (!startTime || !endTime) {
          if (bookingTime?.current)
            bookingTime?.current.scrollIntoView({ behavior: "smooth" });
          return;
        }

        setIsLoading(true);
        if (values.register && values.password) {
          const registerRes = await axiosInstance.post(
            "/api/v1/auth/register",
            Object.assign({
              ...values,
              username: values?.email,
              role: "customer",
            })
          );
          if (registerRes.status === 200) {
            setAlert({
              show: true,
              message: `${t("ACCOUNTREGISTERED", "帳戶註冊成功！")}`,
              type: "success",
            });
            setTimeout(() => {
              setAlert({ show: false, message: "", type: "" });
            }, 5000);
          }
        }
        try {
          const selectedPickupLocation = isBoat
            ? boatlocationsMap[values?.pickupLocation as string] || ""
            : locationsMap[values?.pickupLocation as string] || "";
          const selectedReturnLocation = isBoat
            ? boatlocationsMap[values?.returnLocation as string] || ""
            : locationsMap[values?.returnLocation as string] || "";
          const FinalStartTime = mergeDateAndTime(startDate, startTime);
          const FinalEndTime = mergeDateAndTime(endDate, endTime);
          const res = await axiosInstance.post(
            paymentMethod == "card"
              ? `/api/v1/bookings/attempt/${productId}`
              : `/api/v1/bookings/${productId}`,
            Object.assign({
              ...values,
              pickUpDate: startDate?.toLocaleDateString("en-CA"),
              returnDate: endDate?.toLocaleDateString("en-CA"),
              startDate: FinalStartTime,
              endDate: FinalEndTime,
              car: productId,
              type: "car",
              total:
                formik?.values?.carCoverCheck && data?.price?.carCover > 0
                  ? totalPrice +
                    (data?.price?.carCover * rentalDays + extraCoverPrice ||
                      0) +
                    pickUpDeliveryFee +
                    returnDeliveryFee
                  : totalPrice + pickUpDeliveryFee + returnDeliveryFee,
              deposit: data?.price?.deposit,
              rentalFee: totalPrice,
              rentalDays: rentalDays,
              customer: user?._id,
              paymentMethod: paymentMethod,
              paymentStatus: "PENDING",
              pickupLocation: selectedPickupLocation,
              returnLocation: selectedReturnLocation,
              extraCoverPrice: extraCoverPrice,
              carCoverTotal:
                formik?.values?.carCoverCheck && data?.price?.carCover > 0
                  ? data?.price?.carCover * rentalDays + extraCoverPrice
                  : 0,
              countryPhone: countryPhone,
              countryCode: countryCode,
              totalloss:
                formik?.values?.carCoverCheck && data?.price?.carCover > 0
                  ? data?.price?.totalloss
                  : 0,
              // pickupLocation:
              //   values?.pickupLocation ==
              //   ("Our Shop (Cheung Sha Wan)" ||
              //     "長沙灣興華街38號地下（ 順寧道461號側入）（长沙湾）" ||
              //     "長沙灣興華街38號地下（ 順寧道461號側入）（長沙灣）")
              //     ? "Our Shop (Cheung Sha Wan)"
              //     : values?.pickupLocation ==
              //       ("Hong Kong Island (+$500)" ||
              //         "港岛送车上门 (+$500)" ||
              //         "港島送車上門 (+$500)")
              //     ? "Hong Kong Island (+$500)"
              //     : values?.pickupLocation ==
              //       ("Kowloon (+300)" ||
              //         "九龙送车上门 (+$300)" ||
              //         "九龍送車上門 (+$300)")
              //     ? "Kowloon (+300)"
              //     : values?.pickupLocation ==
              //       ("New Territories (+$500)" ||
              //         "新界送车上门 (+$500)" ||
              //         "新界送車上門 (+$500)")
              //     ? "New Territories (+$500)"
              //     : values?.pickupLocation ==
              //       ("Hong Kong International Airport (+$700)" ||
              //         "机场送车上门 (+$700)" ||
              //         "機場送車上門 (+$700)")
              //     ? "Hong Kong International Airport (+$700)"
              //     : values?.pickupLocation,
              // returnLocation :
            })
          );
          if (res.status === 200) {
            //split card confirm function here
            if (paymentMethod == "card") {
              setBookingInformation(
                Object.assign({
                  ...values,
                  pickUpDate: startDate?.toLocaleDateString("en-CA"),
                  returnDate: endDate?.toLocaleDateString("en-CA"),
                  startDate: FinalStartTime,
                  endDate: FinalEndTime,
                  car: productId,
                  type: "car",
                  total:
                    formik?.values?.carCoverCheck && data?.price?.carCover > 0
                      ? totalPrice +
                        (data?.price?.carCover * rentalDays + extraCoverPrice ||
                          0) +
                        pickUpDeliveryFee +
                        returnDeliveryFee
                      : totalPrice + pickUpDeliveryFee + returnDeliveryFee,
                  deposit: data?.price?.deposit,
                  rentalFee: totalPrice,
                  rentalDays: rentalDays,
                  extraCoverPrice: extraCoverPrice,
                  customer: user?._id,
                  paymentMethod: paymentMethod,
                  pickupLocation: selectedPickupLocation,
                  returnLocation: selectedReturnLocation,
                })
              );
              handlePayWithAirwallex({
                bookingId: res?.data?.data?.bookingId,
              });
            } else {
              setTimeout(
                () => {
                  setAlert({
                    show: true,
                    message: t(
                      "SUCCESSBOOKING",
                      "您的預訂已經確認，感謝您選擇我們的服務！"
                    ),
                    type: "success",
                  });
                },
                values.register ? 1000 : 0
              );
              setIsLoading(false);
              localStorage.removeItem("selectedCarState");
              setTimeout(() => {
                navigate(
                  `/pay-done?bookingId=${res.data.bookingId}&key=${res.data.secretKey}`
                );
              }, 2000);
            }
          }
        } catch (error: any) {
          setIsLoading(false);
          if (
            error?.response?.data?.message ==
            "Return date must be after pickup date"
          ) {
            setAlert({
              show: true,
              message: t(
                "RETURNDATEAFTERPICKUP",
                "Return date must be after pickup date"
              ),
              type: "error",
            });
          } else {
            setAlert({
              show: true,
              message: error?.response?.data?.message,
              type: "error",
            });
          }
          setTimeout(() => {
            setAlert({ show: false, message: "", type: "" });
          }, 5000);
        }
      } catch (err: any) {
        setIsLoading(false);
        // Handle registration failure separately
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
  });

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0 && formik.isSubmitting) {
      formik.setSubmitting(false);
      const firstErrorKey = errorKeys[0];
      const firstErrorElement =
        document.getElementsByName(firstErrorKey)[0] ||
        document.getElementsByClassName(firstErrorKey)[0];
      if (firstErrorElement) {
        const headerHeight = 120;
        const rect = firstErrorElement.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const topPosition = rect.top + scrollTop - headerHeight;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  const loginFormStyles = {
    maxHeight: openLogin ? "1000px" : "0",
    overflow: "hidden",
    transition: "max-height 300ms ease-in-out",
  };

  const passwordFormStyles = {
    maxHeight: openRegister ? "1000px" : "0",
    overflow: "hidden",
    transition: "max-height 300ms ease-in-out",
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8  sticky top-28">
        <h2 className="text-3xl lg:hidden lg:text-4xl font-semibold">
          {t("CONFIRMPAY", "Confirm and payment")}
        </h2>
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={data?.featuredImage}
                alt="Car image"
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {data?.brand}
              </span>
              <span className="text-base font-medium mt-1 block">
                {data?.brand} {data?.model}
              </span>
            </div>
            {!isBoat && (
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {data?.seats} {t("SEATS", "座位數")} ·{" "}
                {data?.gearbox == "Automatic"
                  ? t("AUTOGEARBOX", "自動波")
                  : t("MANUALGEARBOX", "棍波")}
              </span>
            )}
          </div>
        </div>
        {data?.isBoat && (
          <div>
            <label className="text-lg font-semibold" htmlFor="">
              {t("SELECTDATERANGE", "選擇時段")}
            </label>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5">
              {renderRadio(
                "8hrs",
                "1",
                t("BOATFIRST", {
                  defaultValue: "8小時 觀塘公眾碼頭、尖沙咀、中環免費登船",
                }),
                1,
                true
              )}
              {renderRadio(
                "12hrs",
                "2",
                t("BOATSECOND", {
                  defaultValue: "12小時 觀塘公眾碼頭、尖沙咀、中環免費登船",
                }),
                2
              )}
              {renderRadio(
                "24hrs",
                "3",
                t("BOATTHIRD", {
                  defaultValue: "24小時 觀塘公眾碼頭、尖沙咀、中環免費登船",
                }),
                3
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">
            {t("PRICEDETAIL", "價格詳情")}
          </h3>
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div> */}
          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service Charge</span>
            <span>$0</span>
          </div> */}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>
              {" "}
              $
              {totalPrice
                ? ((totalPrice - extraFee) / rentalDays).toFixed(2)
                : 0}{" "}
              x {rentalDays} {t("DAY", "日")}
            </span>
            <span>${totalPrice - extraFee || 0}</span>
          </div>

          {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div> */}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>
              {t("ADDITIONALHOURS", { defaultValue: "額外時數費用" })}
            </span>
            <span>${extraFee} </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>{t("DEPOSIT", "按金")}</span>
            <span>${data?.price?.deposit} </span>
          </div>
          {formik?.values?.carCoverCheck && (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{t("CARCOVERPRICE", "全車保費用")}</span>
              <span>
                ${data?.price?.carCover * rentalDays + extraCoverPrice || 0}{" "}
              </span>
            </div>
          )}
          {!isBoat && (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>{t("SERVICECHARGE", "SERVICECHARGE")}</span>
              <span>${pickUpDeliveryFee + returnDeliveryFee}</span>
            </div>
          )}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>{t("TOTAL", "Total")}</span>
            <span>
              $
              {formik?.values?.carCoverCheck && data?.price?.carCover > 0
                ? totalPrice +
                  pickUpDeliveryFee +
                  returnDeliveryFee +
                  (data?.price?.carCover * rentalDays + extraCoverPrice || 0)
                : totalPrice + pickUpDeliveryFee + returnDeliveryFee}
            </span>
          </div>
        </div>
      </div>
    );
  };

  function extractFeeFromLocation(locationString: string) {
    const regex = /\+(\$[\d,]+)/;
    const match = locationString.match(regex);

    if (match && match[1]) {
      const feeString = match[1].replace("$", "");
      const fee = parseInt(feeString, 10);
      return fee;
    }

    return 0;
  }

  // const extractFeeFromLocation = (locationString: string) => {
  //   const feeLookup: { [key: string]: number } = {
  //     hki: 500,
  //     kowloon: 300,
  //     nt: 500,
  //     airport: 700,
  //   };

  //   return feeLookup[locationString] || 0;
  // };

  const renderMain = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8"
      >
        <h2 className="hidden text-3xl lg:block lg:text-4xl font-semibold">
          {t("CONFIRMPAY", "Confirm and payment")}
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div ref={bookingTime}>
          <div>
            <h3 className="text-2xl font-semibold">
              {t("YOURBOOKING", "您的預訂")}
            </h3>
          </div>

          <div
            ref={formik.errors.pickupLocation ? firstErrorRef : null}
            className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row  z-10"
          >
            {/* <RentalCarDatesRangeInput /> */}

            <LocationInput
              placeHolder=""
              desc={
                isBoat
                  ? t("BOATPICKUPLOCATION", "上船地點") || "上船地點"
                  : t("PICKUPLOCATION", "取車地點") || "Pickup Location"
              }
              className="flex-1 pickupLocation returnLocation"
              location={pickUp}
              setLocation={(value: any) => {
                setPickup(value);
                formik.setFieldValue("pickupLocation", value);
                setCheckoutState((prevState: object) => ({
                  ...prevState,
                  pickUpLocation: value,
                }));
                const fee = extractFeeFromLocation(value);
                setPickUpDeliveryFee(fee);
              }}
              page="checkout"
              boat={isBoat}
            />

            <div className="self-center border-r border-slate-200 dark:border-slate-700 sm:h-8"></div>
            <LocationInput
              placeHolder=""
              desc={
                isBoat
                  ? t("BOATRETURNLOCATION", "落船地點") || "落船地點"
                  : t("RETURNLOCATION", "還車地點") || "Return Location"
              }
              className="flex-1 border-t border-slate-200 dark:border-slate-700 sm:border-none"
              location={returnCar}
              setLocation={(value: any) => {
                setReturnCar(value);
                formik.setFieldValue("returnLocation", value);
                setCheckoutState((prevState: object) => ({
                  ...prevState,
                  returnLocation: value,
                }));
                const fee = extractFeeFromLocation(value);
                setReturnDeliveryFee(fee);
              }}
              page="checkout"
              boat={isBoat}
            />
          </div>
          <div className=" flex flex-col sm:flex-row  z-10">
            {formik.touched.pickupLocation || formik.touched.returnLocation ? (
              <p className="text-red-600 w-6/12">
                {formik.errors.pickupLocation}
              </p>
            ) : null}
            {formik.touched.returnLocation && formik.errors.returnLocation ? (
              <p className="text-red-600 w-6/12">
                {formik.errors.returnLocation}
              </p>
            ) : null}
          </div>
          <div
            className="startDate endDate mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 z-10"
            ref={
              formik.errors.startDate || formik.errors.endDate
                ? firstErrorRef
                : null
            }
          >
            <RentalCarDatesRangeInput
              className="flex-1"
              updateStart={start}
              updateEnd={end}
              updateStartTime={startTime}
              updateEndTime={endTime}
              start={startDate}
              end={endDate}
              productPage={true}
              unavailableDates={unavailableDates}
              changeDate={(starts: any, ends: any) => {
                setStart(starts);
                setEnd(ends);
                setStartTime(null);
                setEndTime(null);
                formik.setFieldValue("startDate", starts);
                formik.setFieldValue("endDate", ends);
                // if (isBoat) {
                //   setEnd(starts);
                //   formik.setFieldValue("endDate", starts);
                // }
                setCheckoutState((prevState: object) => ({
                  ...prevState,
                  startDate: starts,
                  endDate: ends,
                }));
              }}
              changeStartTime={(value: any) => {
                setStartTime(value);
                if (endTime && start && end)
                  calculatePrice(start, end, value, endTime);
              }}
              changeEndTime={(value: any) => {
                setEndTime(value);
                if (start && end && startTime)
                  calculatePrice(start, end, startTime, value);
              }}
              availableLayout={true}
              isTimeUnavailable={isTimeUnavailable}
              maxDateDatepicker={maxDateDatepicker}
              minDateDatepicker={minDateDatepicker}
              setMaxDateDatepicker={setMaxDateDatepicker}
              setMinDateDatepicker={setMinDateDatepicker}
              page="checkout"
              boat={isBoat}
            />
          </div>
          {formik.touched.startDate && formik.errors.startDate ? (
            <p className="text-red-600 w-6/12">{formik.errors.startDate}</p>
          ) : null}
          {formik.touched.endDate && formik.errors.endDate ? (
            <p className="text-red-600 w-6/12">{formik.errors.endDate}</p>
          ) : null}

          {showPickupTimeWarning && showPickupTimeWarning ? (
            <p className="text-red-600 w-6/12">
              {isBoat
                ? t("common:BOATSELECTTIMEWARNING", "請選擇上船時間")
                : t("common:PICKUPTIMEWARNING", "請選擇取車時間")}
            </p>
          ) : null}

          {showReturnTimeWarning && showReturnTimeWarning ? (
            <p className="text-red-600 w-6/12">
              {isBoat
                ? t("common:BOATRETURNTIMEWARNING", "請選擇落船時間")
                : t("common:RETURNTIMEWARNING", "請選擇還車時間")}
            </p>
          ) : null}
          {!isBoat && (
            <div className="flex mt-5  font-bold items-center gap-2 flex-wrap">
              <div>
                <Input
                  className="!w-4 !h-8 cursor-pointer "
                  type="checkbox"
                  name="carCoverCheck"
                  // value={formik.values.carCover}
                  value={
                    !formik.values.carCoverCheck
                      ? coverDefault
                      : formik.values.carCoverCheck
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  // ref={phoneRef}
                />
              </div>
              <Label className="!font-bold !text-lg">
                {t("BUYCARCOVER", "購買全車保") || "購買全車保"}
              </Label>
              <div className="  font-bold">
                [HK${data?.price?.carCover}/{t("DAILY", "日")}]
              </div>
              <div className="!font-bold !text-md">
                [{t("TOTALLOSS", "全損免賠額") || "全損免賠額"} : HK$
                {data?.price?.totalloss}]
              </div>
            </div>
          )}
        </div>

        <div>
          <h3 className="text-2xl font-semibold mb-2">
            {t("BILLINGDETAILS", "賬單詳情")}
          </h3>
          {/* {!user && (
            <a
              className="mt-3 underline cursor-pointer"
              onClick={() => setOpenLogin(!openLogin)}
            >
              Sign in to your account
            </a>
          )} */}
          {/* <div style={loginFormStyles}>
            {openLogin && <LoginPage checkout={true} />}
          </div> */}
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6 space-y-5">
            <div className="flex space-x-5  ">
              <div
                className="flex-1 space-y-1 engName lastName"
                ref={formik.errors.engName ? firstErrorRef : null}
              >
                <Label>{t("NAME", "名字")}</Label>
                <Input
                  name="engName"
                  value={formik.values.engName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // ref={engNameRef}
                />
                {formik.touched.engName && formik.errors.engName ? (
                  <p className="text-red-600">
                    {formik.errors.engName.toString()}
                  </p>
                ) : null}
              </div>
            </div>
            <div
              className="flex-1 space-y-1 engName lastName"
              ref={formik.errors.gender ? firstErrorRef : null}
            >
              <Label> {t("GENDER", { defaultValue: "性別" })}</Label>
              <Select
                id="gender"
                className="mt-1"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
              >
                <option value=""></option>
                <option value="male">
                  {t("MALE", { defaultValue: "男" })}
                </option>
                <option value="female">
                  {t("FEMALE", { defaultValue: "女" })}
                </option>
              </Select>
              {formik.touched.gender && formik.errors.gender ? (
                <p className="text-red-600">
                  {formik.errors.gender.toString()}
                </p>
              ) : null}
            </div>
            <div
              className="space-y-1"
              ref={formik.errors.email ? firstErrorRef : null}
            >
              <Label>{t("EMAIL", "電子郵件")}</Label>
              <Input
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // ref={emailRef}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-600">{formik.errors.email.toString()}</p>
              ) : null}
            </div>
            <div
              className="space-y-1"
              ref={formik.errors.confirmEmail ? firstErrorRef : null}
            >
              <Label>{t("CONFIRMEMAIL", "確認電子郵件")}</Label>
              <Input
                type="email"
                name="confirmEmail"
                value={formik.values.confirmEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // ref={confirmEmailRef}
              />
              {formik.touched.confirmEmail && formik.errors.confirmEmail ? (
                <p className="text-red-600">
                  {formik.errors.confirmEmail.toString()}
                </p>
              ) : null}
            </div>
            <div className="flex">
              <div
                className="space-y-1"
                ref={formik.errors.phone ? firstErrorRef : null}
              >
                <Label>{t("PHONE", "電話")}</Label>
                <CountrySelector
                  buttonClassName="rounded-l-xl !py-4 !h-[44px] !border-r-0 dark:!bg-neutral-900 dark:!border-neutral-700 text-white dark:text-neutral-200"
                  selectedCountry={countryPhone}
                  onSelect={({ iso2, dialCode }) => {
                    setCountryPhone(iso2);
                    setCountryCode(dialCode);
                  }}
                />
              </div>
              <div
                className="space-y-1 !grow"
                ref={formik.errors.phone ? firstErrorRef : null}
              >
                <Input
                  type="tel"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={countryPhone === "hk" ? 8 : 20}
                  className="!rounded-r-xl !rounded-l-none mt-[28px] !grow !border-l-0"
                  // ref={phoneRef}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p className="text-red-600">
                    {formik.errors.phone.toString()}
                  </p>
                ) : null}
              </div>
            </div>

            {!user && (
              <div className="space-y-1">
                <Label>{t("REGISTERACCOUNT", "註冊帳戶？")}</Label>
                <br />
                <Input
                  className="!w-4 !h-8 cursor-pointer !mt-1"
                  type="checkbox"
                  name="register"
                  value={user ? userData?.register : formik.values.register}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setOpenRegister(e?.target?.checked);
                  }}
                  onBlur={formik.handleBlur}
                  // ref={phoneRef}
                />
              </div>
            )}

            <div style={passwordFormStyles}>
              {openRegister && (
                <>
                  <div
                    className="space-y-1"
                    ref={formik.errors.password ? firstErrorRef : null}
                  >
                    <Label>{t("PASSWORD", "密碼")}</Label>
                    <Input
                      type="password"
                      name="password"
                      value={user ? userData?.password : formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // ref={phoneRef}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <p className="text-red-600">{formik.errors.password}</p>
                    ) : null}
                  </div>
                  <div
                    className="space-y-2 pt-5"
                    ref={formik.errors.confirmPassword ? firstErrorRef : null}
                  >
                    <Label>{t("CONFIRMPASSWORD", "確認密碼")}</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      value={
                        user
                          ? userData?.confirmPassword
                          : formik.values.confirmPassword
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // ref={phoneRef}
                    />
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <p className="text-red-600">
                        {formik.errors.confirmPassword}
                      </p>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <h3 className="text-2xl pt-5 font-semibold">
              {t("PAYWITH", "付款方式")}
            </h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setPaymentMethod("card")}
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-300 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      {/* <span className="mr-2.5">Credit card</span> */}
                      <img className="w-8" src={visaPng} alt="visa" />
                      <img
                        className="w-8"
                        src={mastercardPng}
                        alt="mastercard"
                      />
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setPaymentMethod("fps")}
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none text-center ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      {t("FPS", "FPS 轉數快")}
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setPaymentMethod("wechatpay")}
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none text-center ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      WeChatPay
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => setPaymentMethod("alipay")}
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none text-center ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      AlipayHK
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <AirWallex
                    totalPrice={
                      formik?.values?.carCoverCheck && data?.price?.carCover > 0
                        ? totalPrice +
                          (data?.price?.carCover * rentalDays +
                            extraCoverPrice || 0) +
                          pickUpDeliveryFee +
                          returnDeliveryFee
                        : totalPrice + pickUpDeliveryFee + returnDeliveryFee
                    }
                    startPayment={startPayment}
                    setStartPayment={setStartPayment}
                    intentId={intentId}
                    clientSecret={clientSecret}
                    setAlert={setAlert}
                    setIsLoading={setIsLoading}
                    productId={productId}
                    bookingInformation={bookingInformation}
                  />
                  {/* <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div> */}
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="bg-gray-200 mt-10 rounded p-2 dark:text-white dark:bg-gray-600">
                    {t(
                      "PAYMENTSTATEMENT",
                      "付款 QR Code 將會經電郵發送，轉賬後，請將轉賬証明 Whatsapp 6510 7107！"
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="bg-gray-200 mt-10 rounded p-2 dark:text-white dark:bg-gray-600">
                    {t(
                      "PAYMENTSTATEMENT",
                      "付款 QR Code 將會經電郵發送，轉賬後，請將轉賬証明 Whatsapp 6510 7107！"
                    )}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="bg-gray-200 mt-10 rounded p-2 dark:text-white dark:bg-gray-600">
                    {t(
                      "PAYMENTSTATEMENT",
                      "付款 QR Code 將會經電郵發送，轉賬後，請將轉賬証明 Whatsapp 6510 7107！"
                    )}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary type="submit" disabled={isLoading}>
                {t("CONFIRMPAY", "確認並付款")}
                {isLoading ? (
                  <div className="ml-4">
                    <svg
                      className={`animate-spin -ml-1 mr-3 h-5 w-5`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="3"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : null}
              </ButtonPrimary>
            </div>
          </div>
        </div>
        {alert?.show && (
          <Alert type={alert.type} message={alert.message} className="w-full" />
        )}
      </form>
    );
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {loading ? (
        <Loading className="min-h-[50rem]" />
      ) : productId ? (
        <motion.div
          className={`nc-CheckOutPagePageMain ${className}`}
          variants={sectionVariants}
          initial="hidden"
          animate="visible"
        >
          <Helmet>
            <title>
              {t("CHECKOUT", "結賬")} - 長勝租車 GoodwayPass Car Rental
            </title>
          </Helmet>
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
              {renderMain()}
            </div>
            <div className="lg:block flex-grow ">{renderSidebar()}</div>
          </main>
        </motion.div>
      ) : (
        <ErrorPage
          link="/car-rental"
          message={t("SELECTAGAIN", "尚未選擇租用車輛,請重新選擇!")}
          buttonText={t("BACKSHOP", "返回車輛列表！")}
        />
      )}
    </>
  );
};

export default CheckOutPagePageMain;
