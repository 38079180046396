import { useEffect, useState } from "react";

export const useCheckoutState = () => {
  const [checkoutState, setCheckoutState] = useState(() => {
    const savedState = localStorage.getItem("selectedCarState");
    if (savedState) {
      const parsedData = JSON.parse(savedState);
      return {
        ...parsedData,
        startDate: parsedData.startDate ? new Date(parsedData.startDate) : null,
        endDate: parsedData.endDate ? new Date(parsedData.endDate) : null,
      };
    }
    return savedState ? JSON.parse(savedState) : null;
  });

  useEffect(() => {
    if (checkoutState) {
      localStorage.setItem("selectedCarState", JSON.stringify(checkoutState));
    } else {
      localStorage.removeItem("selectedCarState");
    }
  }, [checkoutState]);

  return [checkoutState, setCheckoutState];
};
