import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { SearchContext } from "context/searchContext";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowResize";
import { Locale } from "date-fns"; // Import the Locale type
import { enUS, zhCN, zhTW } from "date-fns/locale"; // import the necessary locales
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { subDays, startOfDay, endOfDay } from "date-fns";
export interface UnDate {
  start: Date;
  end: Date;
}

export interface RentalCarDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  start?: any;
  end?: any;
  pickUpLocation?: string;
  returnLocation?: string;
  productPage?: boolean;
  setStart?: any;
  setEnd?: any;
  setStartTime?: any;
  setEndTime?: any;
  unavailableDates?: any[];
  changeDate?: any;
  availableLayout?: boolean;
  updateStart?: any;
  updateEnd?: any;
  updateStartTime?: Date | null;
  updateEndTime?: Date | null;
  changeStartTime?: any;
  changeEndTime?: any;
  onFilterChange?: any;
  isTimeUnavailable?: any;
  isDateDisabled?: any;
  maxDateDatepicker?: Date;
  minDateDatepicker?: Date;
  setMaxDateDatepicker?: any;
  setMinDateDatepicker?: any;
  page?: string;
  boat?: boolean;
  excludeDates?: UnDate[];
}

type MyLocales = {
  [key: string]: Locale;
};

const locales: MyLocales = {
  en: enUS,
  sc: zhCN,
  tc: zhTW,
};

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  className,
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  pickUpLocation,
  returnLocation,
  productPage,
  unavailableDates,
  changeDate,
  updateStart,
  updateEnd,
  updateStartTime,
  updateEndTime,
  changeStartTime,
  changeEndTime,
  onFilterChange,
  isTimeUnavailable,
  isDateDisabled,
  maxDateDatepicker,
  minDateDatepicker,
  setMaxDateDatepicker,
  setMinDateDatepicker,
  page,
  boat = false,
  excludeDates,
}) => {
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState<Date | null>();
  const [endTime, setEndTime] = useState<Date | null>();
  const [startDate, setStartDate] = useState<Date | null>();
  // const disabledDates = [new Date("2023/03/20"), new Date("2023/03/25")];
  const [endDate, setEndDate] = useState<Date | null>();
  const [showPickupTimeWarning, setShowPickupTimeWarning] = useState(false);
  const [showReturnTimeWarning, setShowReturnTimeWarning] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { t } = useTranslation("common");

  useEffect(() => {
    setStartDate(updateStart);

    if (onFilterChange) {
      setStartTime(updateStart);
    }
  }, [updateStart]);
  useEffect(() => {
    setEndDate(updateEnd);

    if (onFilterChange) {
      setEndTime(updateEnd);
    }
  }, [updateEnd]);

  useEffect(() => {
    if (isTimeUnavailable) {
      setStartTime(updateStartTime);
    }
  }, [updateStartTime]);
  useEffect(() => {
    if (isTimeUnavailable) {
      setEndTime(updateEndTime);
    }
  }, [updateEndTime]);

  const onChangeDate = (dates: [Date | any, Date | any], close: any) => {
    const [starts, ends] = dates;
    setStartDate(starts);
    setEndDate(ends);

    if (changeDate) changeDate(starts, ends);
    if (
      ends == null &&
      startDate != null &&
      starts.toString() === startDate.toString()
    ) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const { dispatch } = useContext(SearchContext);

  const handleSearch = () => {
    setShowPickupTimeWarning(!startTime);
    setShowReturnTimeWarning(!endTime);
    if (!startTime || !endTime) {
      setError(
        boat
          ? t("common:BOATSELECTTIMEWARNING", "請選擇上船時間")
          : t("common:SELECTTIMEWARNING", "請選擇取車時間")
      );
      return;
    }
    setError(null);
    let startDateTime, endDateTime;

    if (startDate && startTime) {
      startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startTime.getHours(),
        startTime.getMinutes(),
        startTime.getSeconds()
      );
    }

    if (endDate && endTime) {
      endDateTime = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endTime.getHours(),
        endTime.getMinutes(),
        endTime.getSeconds()
      );
    }

    dispatch({
      type: "NEW_SEARCH",
      payload: {
        startDate: startDateTime,
        endDate: endDateTime,
        pickUpLocation,
        returnLocation,
      },
    });
    navigate("/search", {
      // state: {
      //   startDate: startDateTime,
      //   endDate: endDateTime,
      //   pickUpLocation,
      //   returnLocation,
      // },
    });
    if (onFilterChange) onFilterChange();
  };

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  const renderInput = useCallback(() => {
    return (
      <>
        <div className="">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {error && (
              <div className="text-red-600 xl:text-[14px] leading-5">
                {error}
              </div>
            )}
            {startDate ? (
              <>
                <span>
                  {startDate?.toLocaleDateString(
                    currentLanguage == "en" ? "en-US" : "zh-HK",
                    {
                      month: "short",
                      day: "2-digit",
                    }
                  )}{" "}
                </span>
                {startTime && (
                  <span>
                    {`${startTime
                      ?.getHours()
                      .toString()
                      .padStart(2, "0")}:${startTime
                      ?.getMinutes()
                      .toString()
                      .padStart(2, "0")}`}
                  </span>
                )}
              </>
            ) : (
              t("ADDDATES", "添加日期")
            )}
            {endDate ? (
              <>
                {" -  "}
                {endDate &&
                  endDate?.toLocaleDateString(
                    currentLanguage == "en" ? "en-US" : "zh-HK",
                    {
                      month: "short",
                      day: "2-digit",
                    }
                  )}{" "}
                {endTime && endTime?.getHours().toString().padStart(2, "0")}
                {endTime && ":"}
                {endTime && endTime?.getMinutes().toString().padStart(2, "0")}
              </>
            ) : (
              ""
            )}
          </span>
          {!startDate && (
            <span className="block mt-1 text-sm  font-semibold leading-none">
              {boat
                ? t("BOATPICKUPRETRUN", "上船 - 落船")
                : t("PICKUPRETRUN", "取車 - 還車")}
            </span>
          )}
        </div>
      </>
    );
  }, [startDate, endDate, startTime, endTime, error, boat]);

  const TimeInput = ({ close, open }: any) => {
    return (
      <div className="flex max-h-[300px] mt-2 mb-2">
        <div className="timepick w-6/12 mr-2 relative">
          <h5 className="mb-2 text-center">
            {boat
              ? t("common:BOATPICKUPTIME", "上船時間")
              : t("common:PICKUPTIME", "取車時間")}
          </h5>
          <DatePicker
            className="text-center py-2 flex-1 cursor-pointer border-primary-700 text-[17px]"
            selected={startTime}
            onChange={(date) => {
              setStartTime(date);
              setShowPickupTimeWarning(!date);
              if (changeStartTime) changeStartTime(date);
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("common:TIME", "時間") || "Time"}
            dateFormat="h:mm aa"
            filterTime={(time) =>
              isTimeUnavailable ? !isTimeUnavailable(time, "pickup") : true
            }
            onFocus={(e) => e.target.blur()}
            minTime={setHours(setMinutes(new Date(), 0), 10)}
            maxTime={setHours(setMinutes(new Date(), 0), 20)}
          />
          <div
            className={`text-red-600 text-center ${
              showPickupTimeWarning ? "block" : "hidden"
            }`}
          >
            {boat
              ? t("common:BOATSELECTTIMEWARNING", "請選擇上船時間")
              : t("common:PICKUPTIMEWARNING", "請選擇取車時間")}
          </div>
        </div>
        <div className="timepick w-6/12 relative">
          <h5 className="mb-2 text-center">
            {boat
              ? t("common:BOATRETURNTIME", "落船時間")
              : t("common:RETURNTIME", "還車時間")}
          </h5>
          <DatePicker
            className="flex-1 py-2 text-center cursor-pointer  border-primary-700 text-[17px]"
            selected={endTime}
            onChange={(date) => {
              setEndTime(date);
              setShowReturnTimeWarning(!date);
              if (changeEndTime) changeEndTime(date);
              close();
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("common:TIME", "時間") || "Time"}
            dateFormat="h:mm aa"
            filterTime={(time) =>
              isTimeUnavailable ? !isTimeUnavailable(time, "return") : true
            }
            onFocus={(e) => e.target.blur()}
            minTime={setHours(setMinutes(new Date(), 0), 10)}
            maxTime={setHours(setMinutes(new Date(), 0), 20)}
          />
          <div
            className={`text-red-600 text-center ${
              showReturnTimeWarning ? "block" : "hidden"
            }`}
          >
            {boat
              ? t("common:BOATRETURNTIMEWARNING", "請選擇落船時間")
              : t("common:RETURNTIMEWARNING", "請選擇還車時間")}
          </div>
        </div>
      </div>
    );
  };

  // const TimeInput = () => (
  //   <div className="flex max-h-[300px] mt-2 mb-2">
  //     <div className="timepick w-6/12 mr-2 relative">
  //       <h5 className="mb-2 text-center">
  //         {" "}
  //         {t("common:PICKUPTIME", "取車時間")}
  //       </h5>
  //       <DatePicker
  //         className="text-center py-2 flex-1 cursor-pointer"
  //         selected={startTime}
  //         onChange={(date) => {
  //           setStartTime(date);
  //           if (changeStartTime) changeStartTime(date);
  //         }}
  //         showTimeSelect
  //         showTimeSelectOnly
  //         timeIntervals={15}
  //         timeCaption={t("common:TIME", "時間") || "Time"}
  //         dateFormat="h:mm aa"
  //         // inline
  //       />
  //     </div>
  //     <div className="timepick w-6/12 relative">
  //       <h5 className="mb-2 text-center">
  //         {t("common:RETURNTIME", "還車時間")}
  //       </h5>
  //       <DatePicker
  //         className="flex-1 py-2 text-center cursor-pointer"
  //         selected={endTime}
  //         onChange={(date) => {
  //           setEndTime(date);
  //           if (changeEndTime) changeEndTime(date);
  //         }}
  //         showTimeSelect
  //         showTimeSelectOnly
  //         timeIntervals={15}
  //         timeCaption={t("common:TIME", "時間") || "Time"}
  //         dateFormat="h:mm aa"
  //         // inline
  //       />
  //     </div>
  //   </div>
  // );
  return (
    <>
      <Popover
        className={`RentalCarDatesRangeInput relative flex ${className}`}
      >
        {({ open, close }) => {
          return (
            <>
              <div
                className={`flex-1 z-10 flex items-center focus:outline-none ${
                  open ? "nc-hero-field-focused" : ""
                }`}
              >
                <Popover.Button
                  className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                  onClickCapture={() => {
                    document.querySelector("html")?.click();
                  }}
                >
                  {renderInput()}

                  {startDate && open && (
                    <span className="dark:text-white ">
                      <ClearDataButton
                        onClick={() => {
                          setStartDate(null);
                          if (setMaxDateDatepicker) setMaxDateDatepicker(null);
                          setMinDateDatepicker(() => {
                            const tomorrow = new Date();
                            tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
                            return tomorrow;
                          });
                          setEndDate(null);
                          if (changeDate) changeDate(null, null);
                        }}
                      />
                    </span>
                  )}
                </Popover.Button>

                {/* BUTTON SUBMIT OF FORM */}
                {hasButtonSubmit && !productPage && (
                  <div className="pr-2 xl:pr-4">
                    <button
                      onClick={handleSearch}
                      type="button"
                      className="h-12 w-12 md:h-16 md:w-16 rounded-full bg-primary-6000 text-white hover:bg-primary-700 flex items-center justify-center focus:outline-none"
                    >
                      <span className="mr-3 hidden">
                        {t("common:SEARCH", "搜尋")}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>

              {/* {open && (
                <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
              )} */}

              <Transition
                // show={isPopoverOpen}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={`absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl md:right-0 md:left-auto md:transform-none ${
                    page == "checkout" && "!md:left-0 md:right-auto"
                  }`}
                >
                  <div className="dark:text-white overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                    <DatePicker
                      locale={locales[currentLanguage]}
                      dateFormat="yyyy-MM-dd"
                      selected={startDate}
                      onChange={(dates) => onChangeDate(dates, close)}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={minDateDatepicker}
                      maxDate={maxDateDatepicker}
                      selectsRange
                      monthsShown={WIN_WIDTH < 768 ? 1 : 2}
                      showPopperArrow={false}
                      inline
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                      // excludeDates={disabledDates}
                      focusSelectedMonth={true}
                      // filterDate={(date) =>
                      //   isDateDisabled ? !isDateDisabled(date) : true
                      // }
                      excludeDateIntervals={
                        unavailableDates &&
                        unavailableDates
                          .map((date) => {
                            let firstDate = date?.pickUpDate;
                            let endDate = date?.returnDate;
                            if (new Date(date?.pickUpDate).getHours() > 9) {
                              firstDate = endOfDay(firstDate);
                            } else {
                              firstDate = startOfDay(firstDate);
                            }
                            if (new Date(date?.returnDate).getHours() < 20) {
                              endDate = endOfDay(subDays(date?.returnDate, 1));
                            } else {
                              endDate = endOfDay(endDate);
                            }
                            if (endDate > firstDate) {
                              return {
                                start: new Date(firstDate),
                                end: new Date(endDate),
                              };
                            } else {
                              return null;
                            }
                          })
                          .filter((date) => date !== null)
                          .map((date) => date as { start: Date; end: Date })
                      }
                      // showTimeInput
                      // customTimeInput={

                      // }
                    />
                    <TimeInput close={close} open={open} />
                    <span
                      className="block cursor-pointer underline w-fit"
                      onClick={() => {
                        if (setMaxDateDatepicker) setMaxDateDatepicker(null);
                        setMinDateDatepicker(() => {
                          const tomorrow = new Date();
                          tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
                          return tomorrow;
                        });
                        setEndDate(null);
                        setStartDate(null);
                        if (changeDate) changeDate(null, null);
                      }}
                    >
                      {t("common:CLEAR", "清除")}
                    </span>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </>
  );
};

export default RentalCarDatesRangeInput;
