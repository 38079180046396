import React from "react";
import NavigationItem from "./NavigationItem";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from "react-i18next";

function Navigation({ isSticky }: any) {
  const { t } = useTranslation("common");
  const menu = [
    {
      id: ncNanoId(),
      href: "/",
      name: t("HOME", { defaultValue: "首頁" }),
      isNew: true,
    },
    {
      id: ncNanoId(),
      href: "/car-rental",
      name: t("CARLIST", { defaultValue: "車輛列表" }),
    },
    {
      id: ncNanoId(),
      href: "/yacht-rental",
      name: t("YACHTRENT", { defaultValue: "遊艇租賃" }),
    },
    {
      id: ncNanoId(),
      href: "/carrepair",
      name: t("CARREPAIR", { defaultValue: "汽車維修服務" }),
    },
    {
      id: ncNanoId(),
      href: "/carparts",
      name: t("CARTOOL", { defaultValue: "汽車零件銷售" }),
    },
    // {
    //   id: ncNanoId(),
    //   href: "/about",
    //   name: "About",
    // },
    {
      id: ncNanoId(),
      href: "/contact",
      name: t("CONTACT", { defaultValue: "聯繫我們" }),
    },
  ];

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
      {menu.map((item) => (
        <NavigationItem key={item.id} menuItem={item} isSticky={isSticky} />
      ))}
    </ul>
  );
}

export default Navigation;
