import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC = () => {
  const { t } = useTranslation("common");
  const widgetMenus: WidgetFooterMenu[] = [
    {
      id: "5",
      title: t("EXPLORE", "探索"),
      menus: [
        { href: "/car-rental", label: t("CARLIST", "車輛列表") },
        { href: "/carrepair", label: t("CARREPAIR", "汽車維修服務") },
        { href: "/carparts", label: t("CARTOOL", "汽車零件銷售") },
        { href: "/yacht-rental", label: t("YACHTRENT", "遊艇租賃") },
        { href: "/contact", label: t("CONTACT", "聯繫我們") },
      ],
    },
    {
      id: "1",
      title: t("ACCOUNT", "我的帳戶"),
      menus: [
        { href: "/account", label: t("MYPROFILE", "我的資料") },
        { href: "/account-savelists", label: t("MYWISHLIST", "我的願望清單") },
        { href: "/account-bookings", label: t("MYBOOKINGS", "我的預約") },
        {
          href: "/account-password",
          label: t("CHANGEPASSWORD", "更改密碼"),
        },
      ],
    },
  ];
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                to={item.href}
                // href={item.href}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 lg:pb-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="mr-5 col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center justify-end lg:justify-start md:col-span-3">
            <SocialsList1 className="flex items-center  space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="mt-24  w-full justify-center flex flex-col flex-wrap  relative">
        <div className="mx-auto">
          Copyright © {new Date().getFullYear()} Goodwaypass Limited. All rights
          reserved.
        </div>
        <div className="mt-5 mx-auto">
          <a href="https://loftygroup.hk">
            Powered by <span className="text-primary-6000">LoftyGroup</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
