import ReactDOM from "react-dom/client";
//
import "rc-slider/assets/index.css";
// STYLE
import "./locales/i18n";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SearchContextProvider } from "context/searchContext";
import { AuthContextProvider } from "context/authContext";
import i18n from "./locales/i18n";
import Loading from "components/Loading/loading";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AuthContextProvider>
    <SearchContextProvider>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<Loading className="min-h-[90rem]" />}>
          <App />
        </Suspense>
      </I18nextProvider>
    </SearchContextProvider>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
