// import Heading from "components/Heading/Heading";
import React from "react";
import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
// import cartool1 from "images/tool/cartool (1).jpg";
// import cartool2 from "images/tool/cartool (2).jpg";
const cartoolImages = Array.from({ length: 37 }, (_, index) =>
  require(`images/tool/cartool (${index + 1}).jpg`)
);

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = cartoolImages.map((image, index) => ({
  id: String(index + 1),
  name: ``,
  job: ``,
  avatar: image,
}));

const SectionPart = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading
        desc="拆車零件有咩好？有些零件其實不需要訂新的，照樣可以長期使用，但是價錢相對新的便宜一大半，是維修車輛更換零件的不二之選！"
        className="text-center pb-12"
      >
        零件商店
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPart;
