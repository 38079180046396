import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useTranslation } from "react-i18next";

const Page404: React.FC = () => {
  const { t } = useTranslation("common");
  return (
    <div className="nc-Page404">
      <Helmet>
        <title>404 - 長勝租車 GoodwayPass Car Rental</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            {t("PAGENOTEXIST", "所尋找的頁面不存在。")}
          </span>
          <div className="pt-8">
            <ButtonPrimary href="/">
              {" "}
              {t("RETURNHOME", "Return Home Page")}
            </ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page404;
