import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

interface FileDropzoneProps {
  label: string;
  fieldName: string;
  fileUrl: string | undefined;
  fileType?: string;
  fileName?: string;
  uploaded?: boolean;
  classname?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removePreviewImage: (inputId: string) => void;
}

const FileDropzone: FC<FileDropzoneProps> = ({
  label,
  onChange,
  removePreviewImage,
  fieldName,
  fileUrl,
  fileType,
  fileName,
  uploaded,
  classname 
}) => {
  const { t } = useTranslation("account");
  return (
    <div className={`flex flex-col  items-center justify-center w-full lg:w-[32%] mb-10 ${classname}`}>
      <div className="text-primary font-md text-[20px] dark:text-white mx-auto mb-5">
        {label}
      </div>
      <div className="relative w-full">
        <label
          htmlFor={fieldName}
          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          {fileUrl ? (
            fileType === "application/pdf" ? (
              <label
                htmlFor={fieldName}
                className="relative flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  {fileName}
                </div>
                <input
                  id={fieldName}
                  type="file"
                  className="hidden"
                  onChange={onChange}
                />
              </label>
            ) : (
              <div className="relative">
                <NcImage
                  containerClassName="flex items-center justify-center"
                  className="w-full max-h-64"
                  src={fileUrl}
                />
              </div>
            )
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {uploaded ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-10 h-10 mb-4 text-green-500 dark:text-green-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-green-500 dark:text-green-400">
                    <span className="font-semibold">
                      {t("UPLOADNEWVERSION", "點擊上傳新版本")}
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">
                      {" "}
                      {t("CLICKUPLOAD", "點擊上傳文件")}
                    </span>
                  </p>
                </>
              )}

              <p className="text-xs text-gray-500 dark:text-gray-400">
                {fieldName == "addressDocument"
                  ? "PNG, JPG or PDF"
                  : "PNG, JPG or WEBP"}
              </p>
            </div>
          )}
          <input
            id={fieldName}
            type="file"
            accept={
              fieldName == "addressDocument"
                ? "image/*, application/pdf"
                : "image/*"
            }
            className="hidden"
            onChange={onChange}
          />
        </label>

        {fileUrl && (
          <a
            onClick={(e) => {
              e.stopPropagation();
              removePreviewImage(fieldName);
            }}
            className="absolute p-1 top-0 right-0 flex items-center justify-center w-5 h-5 -mt-2 -mr-2 text-white rounded-full bg-red-700 hover:scale-125 transition duration-100 cursor-pointer"
          >
            <XMarkIcon className="w-8 h-8" />
          </a>
        )}
      </div>
    </div>
  );
};

export default FileDropzone;
