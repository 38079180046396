import {
  createContext,
  useReducer,
  Dispatch,
  ReactNode,
  useEffect,
} from "react";
import axiosInstance from "utils/axiosInstance";
interface Action {
  type: any;
  payload?: State;
}
interface State {
  user: any;
  loading: boolean;
  loadingUserData: boolean;
  initialLoading: boolean;
  error: any;
}

const INITIAL_STATE: State = {
  user: undefined,
  loading: false,
  loadingUserData: false,
  initialLoading: true,
  error: null,
};
export const AuthContext = createContext<{
  user: any;
  loading: boolean;
  loadingUserData: boolean;
  initialLoading: boolean;
  error: any;
  dispatch: Dispatch<Action>;
}>({
  ...INITIAL_STATE,
  dispatch: () => {}, // Add a default dispatch function
});

const authReducer = (state: State, action: Action): State => {
  // Add ': State' to the return type
  switch (action.type) {
    case "LOGIN_START":
      return {
        ...state,
        user: null,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        user: null,
        loading: false,
        error: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        loading: false,
        error: null,
      };
    case "GET_USER_DATA_START":
      return {
        ...state,
        loadingUserData: true,
      };
    case "GET_USER_DATA_SUCCESS":
      return {
        ...state,
        user: action.payload,
        loadingUserData: false,
        initialLoading: false, // Set initialLoading to false
      };
    case "GET_USER_DATA_FAILURE":
      return {
        ...state,
        loadingUserData: false,
        error: action.payload,
        initialLoading: false, // Set initialLoading to false
      };
    default:
      return state;
  }
};

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, INITIAL_STATE);

  const getUserData = async () => {
    try {
      dispatch({ type: "GET_USER_DATA_START" });
      const res = await axiosInstance.get("/api/v1/auth/userdata");
      dispatch({ type: "GET_USER_DATA_SUCCESS", payload: res.data });
    } catch (err: any) {
      if (err.response.status === 401) {
        // User is not authenticated, handle this case
        dispatch({ type: "GET_USER_DATA_FAILURE", payload: undefined });
      } else {
        // Another error occurred, handle this case
        dispatch({ type: "GET_USER_DATA_FAILURE", payload: err.response.data });
      }
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        loadingUserData: state.loadingUserData,
        initialLoading: state.initialLoading,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
