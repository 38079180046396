import { FC, useContext, useEffect, useState } from "react";
import SectionGridFilterCard from "containers/ListingCarPage/SectionGridFilterCard";
import useFetch from "hooks/useFetch";
import { AuthContext } from "context/authContext";
import axiosInstance from "../utils/axiosInstance";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
export interface ListingCarPageProps {
  className?: string;
}

const brandOfCar = [
  { name: "Audi" },
  { name: "Benz" },
  { name: "BMW" },
  { name: "Honda" },
  { name: "Hyundai" },
  { name: "Kia" },
  { name: "Tesla" },
  { name: "Toyota" },
  { name: "Subaru" },
  { name: "Nissan" },
  { name: "Lexus" },
  { name: "Volvo" },
];

const ShopPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { user } = useContext(AuthContext);
  const { data, loading, error, reFetch } = useFetch(`/api/v1/cars/`);
  const [brands, setBrands] = useState(
    brandOfCar.map((item) => ({ ...item, checked: false }))
  );

  const fetchBrand = async () => {
    const result = await axiosInstance.get("/api/v1/brands");
    const newBrands =
      result?.data.length > 1
        ? result?.data?.map((item: any) => ({ ...item, checked: false }))
        : "";
    if (newBrands) {
      setBrands(newBrands);
    }
  };

  useEffect(() => {
    fetchBrand();
  }, []);

  const handleFilterChange = async (filters: any) => {
    const queryParams = new URLSearchParams(filters);
    await reFetch(`/api/v1/cars?${queryParams}`);
  };
  const [wishlists, setWishList] = useState([]);

  useEffect(() => {
    const fetchWishList = async () => {
      try {
        const res = await axiosInstance.get(
          `/api/v1/users/getcarid/${user._id}`
        );
        setWishList(res.data.carList);
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      fetchWishList();
    }
  }, [user]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className={`nc-ListingCarPage relative ${className}`}
      data-nc-id="ListingCarPage"
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <Helmet>
        <title>租車車輛 - 長勝租車 GoodwayPass Car</title>
      </Helmet>
      <div className="container relative pt-20">
        {/* SECTION */}

        <SectionGridFilterCard
          className="pb-24 lg:pb-28"
          data={data}
          loading={loading}
          onFilterChange={handleFilterChange}
          brands={brands}
          setBrands={setBrands}
          wishList={wishlists}
        />
      </div>
    </motion.div>
  );
};

export default ShopPage;
