import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "context/authContext";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";

export default function AvatarDropdown({ profileImage, userName }: any) {
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const solutions = [
    {
      name: t("ACCOUNT", "Account"),
      href: "/account",
      icon: UserCircleIcon,
    },
    {
      name: t("MYBOOKINGS", "Wishlists"),
      href: "/account-bookings",
      icon: HomeIcon,
    },
    {
      name: t("WISHLISTS", "Wishlists"),
      href: "/account-savelists",
      icon: HeartIcon,
    },
    {
      name: t("CHANGEPASSWORD", "Change Password"),
      href: "/account-password",
      icon: HomeIcon,
    },
  ];

  const logout = async () => {
    try {
      dispatch({ type: "LOGOUT" });
      localStorage.removeItem("user");
      const response = await fetch("/api/v1/auth/logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: user?.username }),
      });
      if (response.ok) {
        navigate("/");
      } else {
        console.log("Error clearing cookie");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="AvatarDropdown ">
      <Popover className="relative flex">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none rounded-2xl focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              onClick={!user ? () => navigate("/login") : () => {}}
            >
              <Avatar
                sizeClass="w-8 h-8 sm:w-9 sm:h-9"
                userName={
                  userName?.engName ||
                  userName?.userName ||
                  userName?.email ||
                  ""
                }
              />
            </Popover.Button>
            {user && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                  <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                      {solutions.map((item, index) => (
                        <Link
                          key={index}
                          to={item.href}
                          onClick={close}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-white">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium text-neutral-500 dark:text-white">
                              {item.name}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </div>
                    <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                    <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                      <button
                        onClick={() => logout()}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-white">
                          <ArrowRightOnRectangleIcon
                            aria-hidden="true"
                            className="w-6 h-6"
                          />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-neutral-500 dark:text-white">
                            {t("LOGOUT", "登出")}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
}
