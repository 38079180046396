import React, { useContext, useEffect, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utils/axiosInstance";
import { AuthContext } from "context/authContext";
import Alert from "components/Alert/alert";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";

const ForgetPassword = () => {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const { user, loading, error } = useContext(AuthContext);
  const { t } = useTranslation("account");
  const [apiLoading, setApiLoading] = useState(false);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(
        `${t("INVALIDEMAILFORMAT", { defaultValue: "Invalid email format" })}`
      )
      .required(`${t("EMAILREQUIRED", { defaultValue: "Email is required" })}`),
  });

  // useEffect(() => {
  //   if (!user) {
  //     // Redirect to login page if user is not logged in
  //     navigate("/login", {
  //       state: { alertMessage: "Please login to access this page." },
  //     });
  //   }
  // }, [user]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setApiLoading(true);
      try {
        // Replace this URL with the correct API endpoint and add the user ID
        const res = await axiosInstance.post(`/api/v1/auth/forgot-password`, {
          email: values.email,
        });
        if (res.status === 200) {
          setAlert({
            show: true,
            message: t(
              "RESETPASSWORDMESSAGE",
              "我們已將密碼重設連結發送至您的電子郵件地址!"
            ),
            type: "notextsuccess",
          });
        }
        setApiLoading(false);
        resetForm();
      } catch (err: any) {
        setApiLoading(false);
        setAlert({
          show: true,
          message: t(
            "RESETPASSWORDMESSAGE",
            "我們已將密碼重設連結發送至您的電子郵件地址!"
          ),
          type: "notextsuccess",
        });
      }
    },
  });
  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  return (
    <div className="p-5 text-center">
      <div className="space-y-6 sm:space-y-8 py-20">
        {/* HEADING */}
        <h2 className="text-3xl font-semibold">
          {t("FORGOTPASSWORD", { defaultValue: "忘記密碼" })}
        </h2>
        <div className="w-14 mx-auto border-b border-neutral-200 dark:border-neutral-700"></div>
        <form
          onSubmit={formik.handleSubmit}
          className="max-w-xl space-y-6 mx-auto"
        >
          <div>
            <label className="block text-left">
              <span className="text-left text-neutral-800 dark:text-neutral-200">
                {t("EMAIL", { defaultValue: "電子郵件" })}
              </span>
              <Input
                id="email"
                type="email"
                className="mt-1 my-2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-600">{formik.errors.email.toString()}</p>
              ) : null}
            </label>
          </div>
          <div className="pt-2">
            <ButtonPrimary type="submit" disabled={apiLoading}>
              {t("RESETPASSWORD", { defaultValue: "重置密碼" })}
              {apiLoading ? (
                <LoadingIcon className="w-4 h-4 ml-2 text-white" />
              ) : null}
            </ButtonPrimary>
          </div>
          {alert?.show && (
            <Alert
              type={alert.type}
              message={alert.message}
              className="w-full"
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
