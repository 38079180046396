import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "shared/Button/Button";

const SearchDropdown = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const inputRef = React.createRef<HTMLInputElement>();

  const handleSearch = (event: any) => {
    if (event.key === "Enter") {
      navigate("/search", { state: search });
    }
  };

  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open, close }) => {
          // if (open) {
          //   setTimeout(() => {
          //     inputRef.current?.focus();
          //   }, 100);
          // }

          return (
            <>
              <Popover.Button className="text-2xl hover:text-neutral-700 md:text-[28px] w-12 h-12 rounded-full  dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search"></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute right-0 z-10 w-screen max-w-sm mt-3"
                >
                  <div className="relative">
                    <Input
                      ref={inputRef}
                      // type="search"
                      placeholder={
                        t("TYPEPRESSENTER", "搜尋車輛型號") || "搜尋車輛型號"
                      }
                      onKeyDown={handleSearch}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      onClick={() => {
                        navigate("/search", { state: search });
                        close();
                      }}
                      className="p-3 !absolute top-[50%] translate-y-[-50%] right-0 text-xl hover:!text-2xl hover:text-primary"
                    >
                      <i className="las la-search text-black cursor-pointer "></i>
                    </button>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </React.Fragment>
  );
};

export default SearchDropdown;
