import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  className = "",
}) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const renderItem = (number: number) => {
    const isActive = number === currentPage;
    const handleClick = () => onPageChange(number);

    return isActive ? (
      <span
        key={number}
        onClick={handleClick}
        className={`inline-flex w-11 h-11 items-center mr-1 mt-1 justify-center rounded-full bg-primary-6000 text-white cursor-pointer ${twFocusClass()}`}
      >
        {number}
      </span>
    ) : (
      <Link
        key={number}
        onClick={handleClick}
        className={`inline-flex w-11 h-11 items-center mr-1 mt-1 justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 cursor-pointer ${twFocusClass()}`}
        to="#"
      >
        {number}
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex justify-center flex-wrap text-base font-medium ${className}`}
    >
      {pageNumbers.map(renderItem)}
    </nav>
  );
};

export default Pagination;
