import React, { FC, useContext, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import { AuthContext } from "context/authContext";
import AvatarDropdown from "./AvatarDropdown";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export interface MainNav1Props {
  className?: string;
  isSticky?: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ className = "", isSticky }) => {
  const { user, initialLoading } = useContext(AuthContext);
  const { t } = useTranslation("common");
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="p-4 lg:px-4 lg:container lg:py-4 lg:py-5 relative flex justify-between items-center">
        <div
          className={`flex justify-start flex-1 items-center space-x-4 sm:space-x-10 ${
            isHomePage ? "text-neutral-700 lg:text-white" : "text-neutral-700"
          }`}
        >
          <Logo />
          <Navigation isSticky={isSticky} />
        </div>
        {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3"> */}
        <div
          className={`flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none dark:!text-neutral-100 ${
            isHomePage ? "text-neutral-700 lg:text-white" : "text-neutral-700"
          } ${isSticky && "!text-neutral-700"}`}
        >
          <div className="hidden xl:flex items-center space-x-0.5">
            <LangDropdown />
            <SwitchDarkMode />
            <SearchDropdown />
            {initialLoading ? (
              <></>
            ) : !user ? (
              <ButtonPrimary href="/login">{t("LOGGIN", "登入")}</ButtonPrimary>
            ) : (
              <>
                {" "}
                <AvatarDropdown
                  profileImage={user?.profileImage}
                  userName={user}
                />
              </>
            )}
          </div>
          <div className="flex xl:hidden items-center justify-between">
            <LangDropdown />
            <div className="hidden md:block">
              <SwitchDarkMode />
            </div>
            <AvatarDropdown
              profileImage={user?.profileImage}
              userName={user}
              className={`${
                isHomePage
                  ? "text-neutral-700 lg:text-white"
                  : "text-neutral-700"
              } ${isSticky && "!text-neutral-700"}`}
            />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
