// Fixed version of the ContractPDF component in contract.tsx

import React, { FC, useEffect, useState, useRef, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import SignatureCanvas from "react-signature-canvas";
import axiosInstance from "utils/axiosInstance";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CustomDialog from "components/CustomDialog/CustomDialog";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Loading from "components/Loading/loading";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import Alert from "components/Alert/alert";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import { useTranslation } from "react-i18next";
import ContractUpload from "./ContractPage/UploadDocument";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ContractPDF = () => {
  const { t } = useTranslation("contract"); // Initialize useTranslation with the namespace "contract"
  const { id } = useParams<{ id: string }>();
  const [PDFBlob, setPDFBlob] = useState<string | null>(null);
  const signatureRef = useRef<any>(null);
  const navigate = useNavigate();
  const [isSigning, setIsSigning] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState("");
  const [searchParams] = useSearchParams();
  const [downloadKey, setDownloadKey] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pdfWidth, setPdfWidth] = useState<number>(800); // Initial width set to 800
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth > 800 ? 800 : window.innerWidth;
      setPdfWidth(newWidth);
    };

    handleResize(); // Set initial width on component mount

    window.addEventListener("resize", handleResize); // Update width on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const fetchCustomerDocument = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.get(`/api/v1/customerBookings/${id}`);
        // setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchCustomerDocument();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await axiosInstance.get(
          `/api/v1/contracts/sign/${id}?key=${searchParams.get("key")}`
        );
        if (result?.status === 200) {
          setPDFBlob(result?.data?.pdfBase64);
        } else {
          setPDFBlob("failed");
        }
      } catch (error) {
        setPDFBlob("failed");
      }
    })();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const goToNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages || 1));
    window.scrollTo(0, 0);
  };

  const saveSignature = () => {
    const trimmedCanvas = signatureRef.current.getTrimmedCanvas();
    if (trimmedCanvas) {
      const imageURL = trimmedCanvas.toDataURL("image/png");
      setImageURL(imageURL);
      setIsSigning(false);
    } else {
      setAlert({
        show: true,
        message: "Please provide your signature before saving.",
        type: "error",
      });
    }
  };

  // Update the saveSignature function to capture and send the signature
  const saveContract = async () => {
    const signatureImage = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    if (!signatureImage) {
      setAlert({
        show: true,
        message: "Please provide your signature before confirming.",
        type: "error",
      });
    }
    const dataToSend = {
      signature: signatureImage,
      contractId: id,
      signingKey: searchParams.get("key"),
    };
    try {
      const response = await axiosInstance.post(
        `/api/v1/contracts/saveContract/${id}`,
        dataToSend
      );

      if (response?.status === 200) {
        setDownloadPDF(response?.data?.pdfBase64);
        setDownloadKey(response?.data?.downloadKey);
        setLoading(false);
      } else {
        setDownloadPDF("failed");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeModal = () => {
    setIsSigning(false);
  };

  const handleDialogRender = () => {
    if (isSigning) {
      signatureRef.current.clear();
    }
  };

  useEffect(() => {
    if (alert.show) {
      const timeout = setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [alert.show]);

  const memoizedFile = useMemo(() => {
    if (PDFBlob) {
      const pdfBytes = atob(PDFBlob);
      return { data: pdfBytes };
    }
    return null;
  }, [PDFBlob, pageNumber]);

  const MemoizedPage: React.FC<{
    pageNumber: number;
    width: number;
    children: any;
  }> = React.memo(({ pageNumber, width, children }) => {
    return (
      <Page pageNumber={pageNumber} width={width} renderTextLayer={false}>
        {children}
      </Page>
    );
  });

  useEffect(() => {
    if (downloadPDF) {
      setPDFBlob(null);
    }
  }, [downloadPDF]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${downloadPDF}`;
    link.download = "contract.pdf";
    link.click();
  };

  return (
    <div className="mb-24 lg:mb-32">
      <div
        className={`lg:container mx-auto ${
          !downloadPDF && "min-h-[550px] lg:min-h-[1135px]"
        } text-center  ${PDFBlob !== "failed" && "flex justify-center"}`}
      >
        {downloadPDF ? ( //remember testing only the !!!!!
          <div>
            {downloadPDF === "failed" ? (
              <>
                <p className="text-3xl mx-auto w-fit pt-32">
                  {t("SIGNING_FAILED", "簽署租車合約失敗, 請聯繫客服人員!")}
                </p>
              </>
            ) : (
              <>
                <p className="text-3xl mx-auto w-fit pt-32 flex pb-10">
                  {t("SIGNING_SUCCESS", "成功簽署租車合約")}{" "}
                  <CheckCircleIcon color="green" className="w-9 h-9 ml-2" />
                </p>
                <ButtonPrimary onClick={handleDownload}>
                  {" "}
                  {t("DOWNLOAD_CONTRACT", "下載合約")}
                </ButtonPrimary>
                <ContractUpload
                  contractSuccess={
                    downloadPDF && downloadPDF !== "failed" ? true : false
                  }
                  id={id}
                  key={downloadKey}
                />
              </>
            )}
          </div>
        ) : (
          <>
            {!PDFBlob ? (
              <Loading className="min-h-[50rem]" />
            ) : PDFBlob === "failed" ? (
              <>
                <p className="text-3xl mx-auto w-fit pt-32">
                  {t(
                    "LINK_EXPIRED",
                    "電子合約連結已失效或過期,請聯繫客服人員!"
                  )}
                </p>
                <button
                  onClick={() => navigate(`/`)}
                  className="mt-10 block mx-auto w-fit px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
                >
                  {t("BACKHOME", "返回主頁")}
                </button>
              </>
            ) : (
              <div className="overflow-hidden">
                {memoizedFile && (
                  <Document
                    file={memoizedFile}
                    noData={false}
                    key={pageNumber}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={
                      "relative min-h-[550px] lg:min-h-[1135px] mx-auto"
                    }
                  >
                    <MemoizedPage pageNumber={pageNumber} width={pdfWidth}>
                      {pageNumber === numPages &&
                        (imageURL ? (
                          <div
                            className="bottom-[39%] lg:bottom-[38%] left-[5%]"
                            style={{
                              position: "absolute",
                              marginRight: "20px",
                              zIndex: 9,
                            }}
                          >
                            <img
                              className="max-w-[80px] lg:max-w-[150px] h-[30px] lg:h-[60px] "
                              src={imageURL}
                              alt="my signature"
                              style={{
                                display: "block",
                                margin: "0 auto",
                                padding: "1px",
                                width: "auto",
                              }}
                            />
                            <a
                              style={{
                                position: "absolute",
                                top: -15,
                                right: -15,
                                background: "white",
                                cursor: "pointer",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                setImageURL(null);
                                signatureRef.current.clear();
                              }}
                            >
                              <XCircleIcon className="h-7 w-7" color="red" />
                            </a>
                          </div>
                        ) : (
                          <div
                            className="w-[100px] h-[20px] sm:w-[180px] sm:h-[36px] lg:w-[250px] lg:h-[50px] bottom-[42%] sm:bottom-[40%]"
                            style={{
                              position: "absolute",
                              // bottom: 446,
                              // left: 28,
                              left: "5%",
                              cursor: "pointer",
                              marginRight: "20px",
                              zIndex: 9, // Add margins for better appearance
                            }}
                            onClick={() => setIsSigning(true)}
                          >
                            <div className="text-gray-500 py-[1px] sm:py-[5px] lg:py-[10px] text-left w-fit text-[16px] sm:text-[30px] lg:text-[40px]  mt-3 font-light border-dashed border">
                              {t("SIGN_HERE", "按下簽署")}
                            </div>
                          </div>
                        ))}
                    </MemoizedPage>
                  </Document>
                )}
                <div className="flex justify-center items-center mt-8">
                  <ButtonSecondary
                    onClick={goToPrevPage}
                    disabled={pageNumber <= 1}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                  </ButtonSecondary>
                  <p className="mx-4">
                    第 {pageNumber} 頁，共 {numPages} 頁
                    {/* Page {pageNumber} of {numPages} */}
                  </p>
                  <ButtonSecondary
                    onClick={goToNextPage}
                    disabled={pageNumber >= (numPages || 1)}
                  >
                    <ChevronRightIcon className="h-5 w-5" />
                  </ButtonSecondary>
                </div>
                <div className="flex justify-center items-center mt-8 ">
                  <ButtonPrimary
                    onClick={() => {
                      setLoading(true);
                      saveContract();
                    }}
                    disabled={!imageURL || loading}
                    className="!px-12"
                  >
                    {t("CONFIRM", "確認")}{" "}
                    {loading ? (
                      <div className="ml-4">
                        <svg
                          className={`animate-spin -ml-1 mr-3 h-5 w-5`}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="3"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ) : null}
                  </ButtonPrimary>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <CustomDialog
        open={isSigning}
        onClose={closeModal}
        onRender={handleDialogRender}
        title={t("SIGN_CONTRACT", "合約簽署") || "合約簽署"}
        actions={
          <>
            <ButtonSecondary onClick={() => signatureRef.current.clear()}>
              {t("CLEAR", "清除")}
            </ButtonSecondary>
            <ButtonPrimary onClick={saveSignature}>
              {t("SAVE", "儲存")}
            </ButtonPrimary>
          </>
        }
      >
        <SignatureCanvas
          ref={signatureRef}
          canvasProps={{
            width: 800,
            height: 200,
            className: "border border-gray-300",
          }}
        />
      </CustomDialog>
      {alert.show && <Alert type={alert.type} message={alert.message} />}
    </div>
  );
};

export default ContractPDF;
