import { FC } from "react";
import { useTranslation } from "react-i18next";
import sc from "images/cn.svg";
import en from "images/gb.svg";
import tc from "images/hk.svg";

export const headerLanguage = [
  {
    id: "English",
    name: "English",
    code: "en",
    active: true,
  },
  {
    id: "Traditional Chinese",
    name: "繁體",
    code: "tc",
  },
  {
    id: "Simplified Chinese",
    name: "简体",
    code: "sc",
  },
];

interface LangDropdownProps {
  panelClassName?: string;
}

const LangDropdown: FC<LangDropdownProps> = ({
  panelClassName = "z-10 w-screen max-w-[280px] px-4 mt-4 right-0 sm:px-0",
}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="">
      {/* <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
             group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <GlobeAltIcon className="w-7 h-7" />
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute ${panelClassName}`}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5  text-gray-700">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7 lg:grid-cols-1">
                    {headerLanguage.map((item, index) => (
                      <a
                        key={index}
                        onClick={() => {
                          close();
                          changeLanguage(item.code);
                        }}
                        className={`cursor-pointer dark:text-white flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          item.code ==
                          (i18n.language || window.localStorage.i18nextLng)
                            ? "bg-gray-100 dark:bg-neutral-700"
                            : "opacity-80"
                        }`}
                      >
                        <div className="">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover> */}
      <div className="relative flex gap-1 w-[80px]">
        {headerLanguage.map((item, index) => {
          if (item.code !== (i18n.language || window.localStorage.i18nextLng)) {
            return (
              <div
                key={index}
                // href={item.href}
                onClick={() => {
                  changeLanguage(item.code);
                }}
                className={`block w-[35px] cursor-pointer dark:text-white items-center p-1  rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                  item.code == (i18n.language || window.localStorage.i18nextLng)
                    ? "bg-gray-100 dark:bg-neutral-700"
                    : "opacity-80"
                }`}
              >
                <img
                  src={
                    item.code == "sc"
                      ? sc
                      : item.code == "tc"
                      ? tc
                      : item.code == "sc"
                      ? sc
                      : en
                  }
                  className="w-[30px] h-auto"
                />
              </div>
            );
          }
          return;
        })}
      </div>
    </div>
  );
};
export default LangDropdown;
