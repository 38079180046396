// Fixed version of the ContractPDF component in contract.tsx

import { useEffect, useState } from "react";
import axiosInstance from "utils/axiosInstance";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loading from "components/Loading/loading";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Alert from "components/Alert/alert";
import { useTranslation } from "react-i18next";

export const FileDownload = () => {
  const { t } = useTranslation("contract");
  const { id } = useParams<{ id: string }>();
  const [PDFBlob, setPDFBlob] = useState<string | null>(null);
  const navigate = useNavigate();
  const [downloadAllow, setDownloadAllow] = useState("");
  const [searchParams] = useSearchParams();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    (async () => {
      try {
        const result = await axiosInstance.get(
          `/api/v1/contracts/getFullContractSignFile/${id}?key=${searchParams.get(
            "key"
          )}`
        );
        if (result?.status === 200) {
          setPDFBlob(result?.data?.pdfBase64);
          setDownloadAllow("yes");
        } else {
          setDownloadAllow("failed");
          setPDFBlob("failed");
        }
      } catch (error) {
        setDownloadAllow("failed");
        setPDFBlob("failed");
      }
    })();
  }, []);

  useEffect(() => {
    if (alert.show) {
      const timeout = setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [alert.show]);

  // useEffect(() => {
  //   if (downloadAllow) {
  //     setPDFBlob(null);
  //   }
  // }, [downloadAllow]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${PDFBlob}`;
    link.download = "contract.pdf";
    link.click();
  };

  return (
    <div className="mb-24 lg:mb-32">
      <div
        className={`lg:container mx-auto text-center  ${
          downloadAllow !== "failed" && "flex justify-center"
        }`}
      >
        {downloadAllow && downloadAllow !== "failed" && PDFBlob ? (
          <div>
            <p className="text-3xl mx-auto w-fit pt-32 flex pb-10">
              {t("DOWNLOAD_MESSAGE", "請按以下連結下載租賃合約")}{" "}
              <CheckCircleIcon color="green" className="w-9 h-9 ml-2" />
            </p>
            <ButtonPrimary onClick={handleDownload}>
              {t("DOWNLOAD_CONTRACT", "下載合約")}
            </ButtonPrimary>
          </div>
        ) : (
          <>
            {!PDFBlob ? (
              <Loading className="min-h-[50rem]" />
            ) : (
              <>
                <p className="text-3xl mx-auto w-fit pt-32">
                  {t(
                    "DOWNLOAD_EXPIRED",
                    "下載連結已失效或過期,請聯繫客服人員!"
                  )}
                </p>
                <button
                  onClick={() => navigate(`/`)}
                  className="mt-10 block mx-auto w-fit px-8 py-3 font-semibold text-white bg-gray-900 rounded dark:bg-violet-400 dark:text-gray-900"
                >
                  {t("BACKHOME", "返回主頁")}
                </button>
              </>
            )}
          </>
        )}
      </div>
      {alert.show && <Alert type={alert.type} message={alert.message} />}
    </div>
  );
};

export default FileDownload;
