import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CarDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import CarCard from "components/CarCard/CarCard";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Input from "shared/Input/Input";
import { debounce } from "lodash";
import Loading from "components/Loading/loading";
import axiosInstance from "utils/axiosInstance";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: carData;
  loading?: boolean;
  searchDetail?: any;
  onFilterChange?: any;
  brands?: any;
  setBrands?: any;
  wishList?: any[];
  itemsPerPage?: number;
  searchState?: string;
  layout?: string;
}
export interface carData {
  data?: CarDataType[];
  number?: number;
}

const brandOfCar = [
  { name: "Audi" },
  { name: "Benz" },
  { name: "BMW" },
  { name: "Honda" },
  { name: "Hyundai" },
  { name: "KIA" },
  { name: "Tesla" },
  { name: "Toyota" },
  { name: "Subaru" },
  { name: "Nissan" },
  { name: "Lexus" },
  { name: "Volvo" },
];

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  loading,
  wishList,
  onFilterChange,
  itemsPerPage = 24,
  searchState = "",
  layout,
}) => {
  const [cars, setCars] = useState<CarDataType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [defaultBrand, setDefaultBrand] = useState(
    brandOfCar.map((item) => ({ ...item, checked: false }))
  );
  const [brands, setBrands] = useState(
    brandOfCar.map((item) => ({ ...item, checked: false }))
  );

  const fetchBrand = async () => {
    const result = await axiosInstance.get("/api/v1/brands");
    const newBrands =
      result?.data.length > 1
        ? result?.data?.map((item: any) => ({ ...item, checked: false }))
        : "";
    if (newBrands) {
      setBrands(newBrands);
      setDefaultBrand(newBrands);
    }
  };

  useEffect(() => {
    if (layout !== "boat") fetchBrand();
  }, []);

  const { t } = useTranslation("home");
  const [appliedFilters, setAppliedFilters] = useState<{
    searchInput: string;
    selectedBrands: Record<string, boolean>;
    priceRange: { min: number; max: number };
  }>({
    searchInput: "",
    selectedBrands: {},
    priceRange: { min: 0, max: Infinity },
  });

  const displayedCarsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data?.data) setCars(data?.data);
  }, [data]);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (displayedCarsRef.current) {
      displayedCarsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // const onPageChange = (pageNumber: number) => {
  //   setCurrentPage(pageNumber);
  //   if (displayedCarsRef.current) {
  //     const rect = displayedCarsRef.current.getBoundingClientRect();
  //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //     const topPosition = rect.top + scrollTop;
  //     window.scrollTo({ top: topPosition, behavior: "smooth" });
  //   }
  // };

  const debouncedSetSearchQuery = useCallback(
    debounce((value: string) => {
      setSearchInput(value);
      setCurrentPage(1); // Add this line
      setAppliedFilters((prev) => ({
        ...prev,
        searchInput: value,
      }));
    }, 300),
    []
  );

  const filteredData: CarDataType[] = useMemo(() => {
    let filteredCars = cars;

    if (appliedFilters.searchInput) {
      filteredCars = filteredCars.filter((eachCar: CarDataType) => {
        const code = eachCar?.code?.toLowerCase();
        const brand = eachCar?.brand?.toLowerCase();
        const model = eachCar?.model?.toLowerCase();
        const query = searchInput.toLowerCase();
        return (
          code?.includes(query) ||
          brand?.includes(query) ||
          model?.includes(query)
        );
      });
    }

    if (
      Object.keys(appliedFilters.selectedBrands).length > 0 &&
      Object.values(appliedFilters.selectedBrands).some(
        (value) => value === true
      )
    ) {
      filteredCars = filteredCars.filter(
        (car: CarDataType) => appliedFilters.selectedBrands[car.brand]
      );
    }

    if (priceRange[0] !== 0 || priceRange[1] !== 10000) {
      filteredCars = filteredCars.filter(
        (car: CarDataType) =>
          car.price.price?.["1"] >= priceRange[0] &&
          car.price.price?.["1"] <= priceRange[1]
      );
    }
    return filteredCars;
  }, [cars, appliedFilters, searchInput]);

  const itemVariants = (index: number) => ({
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: index * 0.1, // 0.1 seconds delay per item
      },
    },
  });

  const resetPriceFilter = () => {
    setPriceRange([0, 10000]);
    setAppliedFilters((prev) => ({
      ...prev,
      priceRange: { min: 0, max: Infinity },
    }));
  };

  const resetBrandFilter = () => {
    setBrands(
      defaultBrand?.length > 0
        ? defaultBrand.map((item) => ({ ...item, checked: false }))
        : brandOfCar.map((item) => ({ ...item, checked: false }))
    );
    setAppliedFilters((prev) => ({ ...prev, selectedBrands: {} }));
  };

  useEffect(() => {
    if (searchState) {
      setSearchInput(searchState);
      setCurrentPage(1); // Add this line
      setAppliedFilters((prev) => ({
        ...prev,
        searchInput: searchState,
      }));
    }
  }, [searchState]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className} mt-[0px] md:mt-[80px]`}
      data-nc-id="SectionGridFilterCard"
      ref={displayedCarsRef}
    >
      {layout !== "boat" && (
        <Heading2
          heading={t("HOMEDESC2", "超過200部名貴車款")}
          subHeading={
            <span className="block text-neutral-500 dark:text-neutral-400 mt-3 text-center">
              {t("HOMEDESC", "萬尺店舖可在場揀選車款！")}
            </span>
          }
        />
      )}

      {loading ? (
        <Loading className="min-h-[50rem]" />
      ) : (
        <>
          {layout !== "boat" && (
            <div className="mb-8 lg:mb-11 flex justify-between">
              <TabFilters
                // selectedBrands={selectedBrands}
                // updateSelectedBrands={setSelectedBrands}
                brands={brands}
                setBrands={setBrands}
                priceRange={priceRange}
                setPriceRange={setPriceRange}
                onApply={() => {
                  // Create a new object with the brand names as keys and their checked status as values
                  const selectedBrands = brands.reduce(
                    (acc, brand) => ({ ...acc, [brand.name]: brand.checked }),
                    {}
                  );

                  setAppliedFilters((prev) => ({
                    ...prev,
                    selectedBrands,
                    priceRange: { min: priceRange[0], max: priceRange[1] },
                  }));
                  setCurrentPage(1);
                }}
                resetPriceFilter={resetPriceFilter}
                resetBrandFilter={resetBrandFilter}
              />

              <div className="mb-8 lg:mb-11 mb-[2px] w-[200px]  md:w-[300px]">
                <Input
                  type="search"
                  placeholder={t("common:SEARCH") || "搜尋"}
                  defaultValue={searchState ? searchState : ""}
                  className=""
                  onChange={(e) => debouncedSetSearchQuery(e.target.value)}
                />
              </div>
            </div>
          )}

          {loading ? (
            <Loading className="min-h-[50rem]" />
          ) : filteredData?.length > 0 ? (
            <>
              <div
                className={`grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${
                  layout == "boat" && "xl:!grid-cols-2"
                }`}
              >
                {filteredData
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((car, index) => {
                    return (
                      <motion.div
                        key={index}
                        initial="hidden"
                        animate="visible"
                        variants={itemVariants(index)}
                      >
                        <CarCard
                          layout={layout}
                          key={index}
                          data={car}
                          wishList={wishList?.includes(car?._id) ? true : false}
                        />
                      </motion.div>
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              <div className="w-full text-center">
                <p className="text-3xl pb-5 mb-5 text-center">
                  <div className="w-24 h-24 items-center rounded-full mx-auto flex justify-center mb-5 bg-gray-200 text-center text-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-12 h-12"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                      />
                    </svg>
                  </div>
                  <div className="text-gray-400 font-bold">
                    {layout !== "boat"
                      ? t("NOCAR", { defaultValue: "找不到車輛" })
                      : t("NOBOAT", { defaultValue: "找不到遊艇" })}
                  </div>
                </p>
              </div>
            </>
          )}

          {layout !== "boat" && (
            <div className="flex mt-14 justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(filteredData?.length / itemsPerPage)}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
