import axiosInstance from "../../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { AuthContext } from "context/authContext";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export interface BtnLikeIconProps {
  className?: string;
  colorClass?: string;
  isLiked?: boolean;
  carId?: string | number;
  detailPage?: boolean;
  saveList?: boolean;
  wishList?: boolean;
}

const BtnLikeIcon: FC<BtnLikeIconProps> = ({
  className = "",
  colorClass = "text-white bg-black bg-opacity-30 hover:bg-opacity-50",
  carId,
  detailPage,
  wishList,
  saveList,
}) => {
  const { user } = useContext(AuthContext);
  const [likedState, setLikedState] = useState(saveList || wishList);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    if (wishList) {
      setLikedState(wishList);
    }
  }, [wishList]);

  const navigate = useNavigate();

  const addSaveList = async () => {
    try {
      const res = await axiosInstance.put(`/api/v1/users/${user._id}/savecar`, {
        carId: carId,
      });
      if (res.status === 200) {
        setLikedState(!likedState);
      }
    } catch (error: any) {
      // if (error?.response?.status == 401)
      navigate("/login");
      setAlert({
        show: true,
        message: "Please sign in to your account.",
        type: "error",
      });
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
    }
  };

  const DeleteCarList = async () => {
    try {
      const res = await axiosInstance.delete(
        `/api/v1/users/${user._id}/deletecar`,
        {
          data: {
            carId: carId,
          },
        }
      );
      if (res.status === 200) {
        setLikedState(!likedState);
      }
    } catch (error: any) {
      navigate("/login");
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 5000);
    }
  };

  return detailPage ? (
    <>
      {/* {alert?.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          className="w-fit-content fixed top-1 right-0 z-50 none"
        />
      )} */}

      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
          <span
            onClick={() => {
              if (likedState) {
                DeleteCarList();
              } else {
                addSaveList();
              }
            }}
            className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill={likedState ? "currentColor" : "none"}
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
            {/* <span className="hidden sm:block ml-2.5">Save</span> */}
          </span>
        </div>
      </div>
    </>
  ) : (
    <>
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          className="w-fit-content fixed top-1 right-0 z-50"
        />
      )}
      <div
        className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
          likedState ? "nc-BtnLikeIcon--liked" : ""
        }  ${colorClass} ${className}`}
        data-nc-id="BtnLikeIcon"
        // title={ t("MYPROFILE", "Save")|| Save}
        onClick={(event) => {
          if (likedState) {
            event.stopPropagation();
            event.preventDefault();
            DeleteCarList();
          } else {
            event.stopPropagation();
            event.preventDefault();
            addSaveList();
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill={likedState ? "currentColor" : "none"}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </div>
    </>
  );
};

export default BtnLikeIcon;
