import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface AlertProps {
  className?: string;
  title?: string;
  message?: string;
  type?: string;
}

const Alert: FC<AlertProps> = ({ className, title, message, type }) => {
  const { t } = useTranslation("common");
  let classes = className;
  let icon;
  switch (type) {
    case "default":
      classes +=
        " border-blue-100 bg-blue-100 text-blue-600 bg-status-infoBg text-status-info z-50";
      title = "Info";
      icon = (
        <svg
          className="fill-current h-6 w-6 text-teal-500 mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
        </svg>
      );
      break;
    case "info":
      classes +=
        " border-blue-100 bg-blue-100 text-blue-600 bg-status-infoBg text-status-info";
      title = "Info";
      icon = (
        <svg
          className="fill-current h-6 w-6 text-teal-500 mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
        </svg>
      );
      break;
    case "success":
      classes += " border-green-100 bg-green-100 text-green-700";
      title = "Success";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="flex-none fill-current text-green-500 h-6 w-6 mr-4"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
        </svg>
      );
      break;
    case "error":
      classes += " border-red-100 bg-red-100 text-red-700";
      title = "Error";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="flex-none fill-current text-red-500 h-6 w-6 mr-4"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z" />
        </svg>
      );
      break;
    case "warning":
      classes += " bg-status-warningBg text-status-warning ";
      title = "Warning";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="flex-none fill-current text-red-500 h-6 w-6 mr-4"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z" />
        </svg>
      );
      break;
    case "notextsuccess":
      classes += " border-green-100 bg-green-100 text-green-700";
      title = "";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="flex-none fill-current text-green-500 h-6 w-6 mr-4"
        >
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
        </svg>
      );
      break;
    default:
      break;
  }

  return (
    <div
      className={` z-50 transition-opacity duration-300 ease-in  border-t-4  rounded-b px-4 py-3 shadow-md  ${classes}`}
      role="alert"
    >
      <div className="flex z-50">
        <div className="py-1 z-50">{icon}</div>
        <div className="text-left">
          <p className="font-bold z-50">
            {title == "Success"
              ? t("SUCCESS", "成功")
              : title == "Error"
              ? t("ERROR", "失敗")
              : title == "Warning"
              ? t("WARNING", "警告")
              : ""}
          </p>
          <p className="text-sm z-50">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
