import SectionHero from "components/SectionHero/SectionHero";
import { Bg2Glassmorphism } from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridFilterCard from "containers/ListingCarPage/SectionGridFilterCard";
import useFetch from "hooks/useFetch";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
function PageBoat() {
  const { data, loading, error, reFetch } = useFetch(`/api/v1/cars/boat/`);
  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <motion.div
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
      className={`nc-PageBoat overflow-hidden`}
      data-nc-id="PageBoat"
    >
      <Helmet>
        <title>遊艇 - 長勝租車 GoodwayPass Car Rental </title>
      </Helmet>
      <div className="nc-PageHome relative overflow-hidden">
        {/* GLASSMOPHIN */}
        <Bg2Glassmorphism />

        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
          {/* SECTION HERO */}
          <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

          {/* SECTION */}
          <SectionGridFilterCard
            className="pb-24 lg:pb-28"
            layout="boat"
            data={data}
            loading={loading}
          />

          <SectionHowItWork />
        </div>
      </div>
    </motion.div>
  );
}

export default PageBoat;
