// import Heading from "components/Heading/Heading";
import React from "react";
import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import carrepair2 from "images/repair/carrepair (2).jpg";
import carrepair3 from "images/repair/carrepair (3).jpg";
import carrepair4 from "images/repair/carrepair (4).jpg";
import carrepair5 from "images/repair/carrepair (5).jpg";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `打正噴油`,
    job: "",
    avatar: carrepair2,
  },
  {
    id: "4",
    name: `頭泵把修復噴油`,
    job: "",
    avatar: carrepair3,
  },
  {
    id: "3",
    name: `左前門撞凹打正噴油`,
    job: "",
    avatar: carrepair4,
  },
  {
    id: "2",
    name: `全車貼膜`,
    job: "",
    avatar: carrepair5,
  },
];

const SectionPaint = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading desc="專業 燒焊噴油 車身修復 打正">車身噴油修復</Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionPaint;
