import { Navigate } from "react-router-dom";

const UnprotectedRoute = ({ user, children, loading }: any) => {
  if (user && !loading) {
    return <Navigate to="/account" replace />;
  }

  return children;
};

export default UnprotectedRoute;
