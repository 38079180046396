import { FC } from "react";
import { CarDataType } from "data/types";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import NcImage from "shared/NcImage/NcImage";
import { useTranslation } from "react-i18next";

export interface CarCardProps {
  className?: string;
  data: CarDataType;
  size?: "default" | "small";
  wishList?: boolean;
  saveList?: boolean;
  layout?: string;
}

const CarCard: FC<CarCardProps> = ({
  size = "default",
  className = "",
  data,
  wishList,
  saveList,
  layout,
}) => {
  // const {
  //   featuredImage,
  //   title,
  //   href,
  //   like,
  //   saleOff,
  //   isAds,
  //   price,
  //   reviewStart,
  //   reviewCount,
  //   seats,
  //   gearshift,
  // } = data;
  const { t } = useTranslation("common");

  const renderSliderGallery = () => {
    return (
      <div
        className={`relative w-full rounded-2xl overflow-hidden ${
          layout == "boat" && "h-[90%] !rounded-sm"
        }`}
      >
        <div
          className={`aspect-w-16 aspect-h-9 ${
            layout == "boat" && "!aspect-h-8"
          } `}
        >
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={data?.featuredImage}
            alt={data?.brand + " " + data?.model}
          />
        </div>
        {layout !== "boat" && (
          <BtnLikeIcon
            isLiked={data?.like}
            className="absolute right-3 top-3 z-5"
            carId={data?._id}
            wishList={wishList}
            saveList={saveList}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className="line-clamp-1">
                {data?.brand} {data?.model}
              </span>
            </h2>
          </div>
          {layout !== "boat" && (
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              {data?.seats && (
                <>
                  <span className="">
                    {data?.seats} {t("SEATS", "座")}
                  </span>
                  <span>-</span>
                </>
              )}
              {data?.gearbox && (
                <span className="">
                  {data?.gearbox == "Automatic"
                    ? t("AUTOGEARBOX", "自動波")
                    : t("MANUALGEARBOX", "棍波")}
                </span>
              )}
            </div>
          )}
        </div>

        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            $
            {layout == "boat"
              ? data?.price?.price?.["2"]
              : data?.price?.price?.["1"]}
            {layout == "boat" && "+"}
            {size === "default" && layout !== "boat" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /{t("DAY", "天")}
              </span>
            )}
          </span>
          {/* <StartRating
                reviewCount={data?.reviewCount}
                point={data?.reviewStart}
              /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl  bg-white dark:bg-neutral-900  ${className}`}
      data-nc-id="CarCard"
    >
      <Link
        to={`/car-rental/${data?.brand.replace(
          /\s+/g,
          "-"
        )}-${data?.model?.replace(/\s+/g, "-")}-${data?.code}`}
        className="flex flex-col"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard;
