// CustomDialog.tsx

import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";

const CustomDialog = ({
  open,
  onClose,
  children,
  onRender,
  title,
  actions,
}: {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  onRender?: () => void;
  title?: string;
  actions?: React.ReactNode;
}) => {
  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        open ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center lg:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"
          onClick={onClose}
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-between px-4 py-2">
            <h2 className="text-lg font-semibold">{title}</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" /> {/* Add the XIcon component */}
            </button>
          </div>
          {children}
          {actions && (
            <div className="flex justify-end px-4 py-2">{actions}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomDialog;
