import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import convertNumbThousand from "utils/convertNumbThousand";
import Slider from "rc-slider";
import { useTranslation } from "react-i18next";

// DEMO DATA
const brandOfCar = [
  { name: "Audi", checked: false },
  { name: "Benz", checked: false },
  { name: "BMW", checked: false },
  { name: "Honda", checked: false },
  { name: "Hyundai", checked: false },
  { name: "Kia", checked: false },
  { name: "Tesla", checked: false },
  { name: "Toyota", checked: false },
  { name: "Subaru", checked: false },
  { name: "Nissan", checked: false },
  { name: "Lexus", checked: false },
  { name: "Volvo", checked: false },
];

export interface TabFiltersProps {
  // selectedBrands: Record<string, boolean>;
  // updateSelectedBrands: (brands: Record<string, boolean>) => void;
  brands: any;
  setBrands: any;
  onApply: () => void;
  priceRange?: [number, number];
  setPriceRange?: (priceRange: [number, number]) => void;
  resetPriceFilter?: () => void;
  resetBrandFilter?: () => void;
}

const TabFilters = ({
  // onFilterChange,
  brands,
  setBrands,
  priceRange,
  setPriceRange,
  onApply,
  resetPriceFilter,
  resetBrandFilter,
}: any) => {
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  // const [rangePrices, setRangePrices] = useState([0, 10000]);

  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setBrands((prevBrands: any) =>
      prevBrands.map((item: any) => {
        if (item.name === name) {
          return { ...item, checked };
        }
        return item;
      })
    );
  };

  const { t } = useTranslation("common");

  // const handleClearFilters = (type: string) => {
  //   if (type == "price") {
  //     setPriceRange([0, 10000]);
  //   }
  //   if (type == "brand") {
  //     setBrands((prevBrands: any) =>
  //       prevBrands.map((item: any) => {
  //         item.checked = false;
  //         return item;
  //       })
  //     );
  //   }
  // };

  const renderXClear = () => {
    return (
      <span
        className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center
       cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsBrandOfCars = () => {
    return (
      <Popover className="relative">
        {({ open, close: closeModal }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              } ${
                brands.some((obj: any) => obj.checked === true) &&
                "bg-primary-50 text-primary-700 border-primary-500"
              }`}
            >
              <span>{t("BRAND", "品牌")}</span>
              {brands.some((obj: any) => obj.checked === true) ? (
                <a
                  className="ml-3"
                  onClick={() => {
                    closeModal();
                    resetBrandFilter();
                  }}
                >
                  {renderXClear()}
                </a>
              ) : (
                <i className="las la-angle-down ml-2"></i>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-[100] w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {brands.map((item: any) => (
                      <div key={item.name}>
                        <Checkbox
                          className="cursor-pointer"
                          name={item.name}
                          label={item.name}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        closeModal();
                        resetBrandFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("CLEAR", "清除")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        closeModal();
                        onApply();
                        // handleApplyFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("APPLY", "篩選")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500  focus:outline-none ${
                (priceRange[0] !== 0 || priceRange[1] !== 10000) &&
                "border-primary-500 bg-primary-50 text-primary-700"
              }`}
            >
              {priceRange[0] == 0 && priceRange[1] == 10000 ? (
                <>
                  <span>{t("PRICE", "價格")}</span>
                  <i className="las la-angle-down ml-2"></i>
                </>
              ) : (
                <>
                  <span>
                    {`$${convertNumbThousand(
                      priceRange[0]
                    )} - $${convertNumbThousand(priceRange[1])}`}{" "}
                  </span>
                  <a className="ml-3" onClick={resetPriceFilter}>
                    {renderXClear()}
                  </a>
                </>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">
                        {t("PRICEPERDAY", "每日")}
                      </span>
                      <Slider
                        range
                        className="text-red-400"
                        min={0}
                        max={10000}
                        defaultValue={[priceRange[0], priceRange[1]]}
                        allowCross={false}
                        onChange={(e) => setPriceRange(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t("MINPRICE", "最低價格")}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={priceRange[0]}
                            onChange={(e) => {
                              setPriceRange([e.target.value, priceRange[1]]);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t("MAXPRICE", "最高價格")}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              $
                            </span>
                          </div>
                          <input
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={priceRange[1]}
                            onChange={(e) => {
                              setPriceRange([priceRange[0], e.target.value]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        resetPriceFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("CLEAR", "清除")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        onApply();
                        // handleApplyFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("APPLY", "篩選")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // Morefilter for mobile mode
  const renderTabMobileFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer ${
            (priceRange[0] !== 0 ||
              priceRange[1] !== 10000 ||
              brands.some((obj: any) => obj.checked === true)) &&
            "border-primary-500 bg-primary-50 text-primary-700"
          }`}
          onClick={openModalMoreFilterMobile}
        >
          <span>
            <span className="hidden sm:inline">{t("CAR", "車輛")}</span>{" "}
            {t("FILTERS", "車輛列表")}
            {priceRange[0] !== 0 ||
            (priceRange[1] !== 10000 &&
              brands.some((obj: any) => obj.checked === true))
              ? "(2)"
              : priceRange[0] !== 0 ||
                (priceRange[1] !== 10000 &&
                  brands.some((obj: any) => obj.checked !== true))
              ? "(1)"
              : priceRange[0] == 0 &&
                priceRange[1] == 10000 &&
                brands.some((obj: any) => obj.checked === true)
              ? "(1)"
              : ""}
          </span>

          {priceRange[0] !== 0 ||
          priceRange[1] !== 10000 ||
          brands.some((obj: any) => obj.checked === true) ? (
            <a
              className="ml-3"
              onClick={() => {
                resetBrandFilter();
                resetPriceFilter();
              }}
            >
              {renderXClear()}
            </a>
          ) : (
            <i className="las la-angle-down ml-2"></i>
          )}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t("CARFILTERS", "車輛篩選")}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ------ */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          {t("BRANDOFCAR", "車輛品牌")}
                        </h3>
                        <div className="mt-6 relative ">
                          <div className="sm:gap-8 flex flex-wrap">
                            {brands.map((item: any) => (
                              <div key={item.name} className="mr-4 mb-2">
                                <Checkbox
                                  className="cursor-pointer"
                                  name={item.name}
                                  label={item.name}
                                  checked={item.checked}
                                  onChange={(checked) =>
                                    handleCheckboxChange(item.name, checked)
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          {t("RANGEPRICES", "價格範圍")}
                        </h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={10000}
                                value={[priceRange[0], priceRange[1]]}
                                // defaultValue={[priceRange[0], priceRange[1]]}
                                allowCross={false}
                                onChange={(e) => setPriceRange(e as number[])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t("MINPRICE", "最低價格")}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={priceRange[0]}
                                    onChange={(e) => {
                                      setPriceRange([
                                        e.target.value,
                                        priceRange[1],
                                      ]);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t("MAXPRICE", "最高價格")}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={priceRange[1]}
                                    onChange={(e) => {
                                      setPriceRange([
                                        priceRange[0],
                                        e.target.value,
                                      ]);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("CLEAR", "清除")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        // handleApplyFilters();
                        closeModalMoreFilterMobile();
                        onApply();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("APPLY", "篩選")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {renderTabsBrandOfCars()}
        {renderTabsPriceRage()}
        {/* {renderTabsGuestsAndBags()}
        {renderTabMoreFilter()} */}
      </div>
      <div className="flex lg:hidden space-x-4">{renderTabMobileFilter()}</div>
    </div>
  );
};

export default TabFilters;
