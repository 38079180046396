// import Heading from "components/Heading/Heading";
import React from "react";
import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import carrepair1 from "images/repair/carrepair (1).jpg";
import carrepair6 from "images/repair/carrepair (6).jpg";
import carrepair7 from "images/repair/carrepair (7).jpg";
import carrepair10 from "images/repair/carrepair (10).jpg";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `迫力碟`,
    job: "",
    avatar: carrepair1,
  },
  {
    id: "4",
    name: ` 雞髀 / 搖臂 - 懸掛系統`,
    job: "",
    avatar: carrepair6,
  },
  {
    id: "3",
    name: `機油`,
    job: "",
    avatar: carrepair7,
  },
  {
    id: "2",
    name: `車身部件`,
    job: "",
    avatar: carrepair10,
  },
];

const SectionMech = () => {
  return (
    <div className="nc-SectionFounder relative">
      <Heading desc=" 燒焊 機器維修、改裝外觀" className="text-center pb-12">
        機器維修
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionMech;
