import React, { useState, ChangeEvent, useEffect } from "react";

function useImageInput(defaultImage: string) {
  const [previewImage, setPreviewImage] = useState<string | undefined>(
    defaultImage
  );
  const [imageChanged, setImageChanged] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string | undefined>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const removeImage = (inputId: string) => {
    setPreviewImage("");
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.value = "";
    } else {
      console.error(input);
    }
    setFileType(undefined);
    setFileName("");
    setImageChanged(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      if (file?.type !== "application/pdf") {
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setPreviewImage("pdf");
      }
      setFileType(file?.type); // set the file type
      setFileName(file?.name);
      setImageChanged(true);
    }
  };

  return {
    previewImage,
    setPreviewImage,
    imageChanged,
    removeImage,
    handleChange,
    fileName,
    fileType,
    setFileType,
    selectedFile,
  };
}

export default useImageInput;
