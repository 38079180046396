import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface ScrollToTopProps {}

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
  const locationPathName = useLocation().pathname;
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [locationPathName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100);

    return () => clearTimeout(timer);
  }, [locationPathName]);

  return null;
};

export default ScrollToTop;

// import { useEffect } from "react";
// import { useLocation, useNavigationType } from "react-router-dom";

// function SmoothScroll({ children }: any) {
//   const location = useLocation();
//   const navType = useNavigationType();

//   useEffect(() => {
//     if (navType !== "POP") {
//       const timer = setTimeout(() => {
//         window.scrollTo({ top: 0, behavior: "smooth" });
//       }, 100);

//       return () => clearTimeout(timer);
//     }
//   }, [location]);

//   useLayoutEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);

//   useEffect(() => {
//     if (navType !== "POP") {
//       setTimeout(() => {
//         window.scrollTo(0, 0);
//       }, 100);
//     }
//   }, [location]);

//   return <>{children}</>;
// }

// export default SmoothScroll;
