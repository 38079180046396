import React, { useContext, useEffect, useState } from "react";
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../utils/axiosInstance";
import { AuthContext } from "context/authContext";
import Alert from "components/Alert/alert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";

const AccountPass = () => {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  const { user, loading, error } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation("account");
  const [apiLoading, setApiLoading] = useState(false);
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(
      `${t("CURRENTPWREQUIRED", { defaultValue: "必須輸入目前密碼" })}`
    ),
    newPassword: Yup.string()
      .min(
        6,
        `${t("PASSWORDTOOSHORT", { defaultValue: "密碼長度至少為 6 個字元" })}`
      )
      .required(`${t("NEWPWREQUIRED", { defaultValue: "必須輸入新密碼" })}`),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), undefined],
        `${t("PWMATCH", { defaultValue: "密碼不符" })}`
      )
      .required(
        `${t("CONFIRMPWREQUIRED", { defaultValue: "必須輸入確認密碼" })}`
      ),
  });

  // useEffect(() => {
  //   if (!user) {
  //     // Redirect to login page if user is not logged in
  //     navigate("/login", {
  //       state: { alertMessage: "Please login to access this page." },
  //     });
  //   }
  // }, [user]);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setApiLoading(true);
      try {
        // Replace this URL with the correct API endpoint and add the user ID
        const res = await axiosInstance.put(
          `/api/v1/users/${user._id}/change-password`,
          {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
          }
        );
        setAlert({
          show: true,
          message: res.data.message,
          type: "success",
        });
        setApiLoading(false);
        resetForm();
      } catch (err: any) {
        setApiLoading(false);
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
  });
  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
            {t("UPDATEYOURPASSWORD", { defaultValue: "更改密碼" })}
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form onSubmit={formik.handleSubmit} className="max-w-xl space-y-6">
            <div>
              <Label>
                {t("CURRENTPASSWORD", { defaultValue: "目前密碼" })}
              </Label>
              <Input
                id="currentPassword"
                type="password"
                className="mt-1.5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
              />
              {formik.touched.currentPassword &&
              formik.errors.currentPassword ? (
                <p className="text-red-600">{formik.errors.currentPassword}</p>
              ) : null}
            </div>
            <div>
              <Label>{t("NEWPASSWORD", { defaultValue: "新密碼" })}</Label>
              <Input
                id="newPassword"
                type="password"
                className="mt-1.5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <p className="text-red-600">{formik.errors.newPassword}</p>
              ) : null}
            </div>
            <div>
              <Label>
                {t("CONFIRMPASSWORD", { defaultValue: "確認新密碼" })}
              </Label>
              <Input
                id="confirmPassword"
                type="password"
                className="mt-1.5"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-red-600">{formik.errors.confirmPassword}</p>
              ) : null}
            </div>
            <div className="pt-2">
              <ButtonPrimary type="submit" disabled={apiLoading}>
                {t("UPDATEPASSWORD", { defaultValue: "更新密碼" })}
                {apiLoading ? (
                  <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                ) : null}
              </ButtonPrimary>
            </div>
            {alert?.show && (
              <Alert
                type={alert.type}
                message={alert.message}
                className="w-full"
              />
            )}
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
