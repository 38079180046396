import { FC, useContext, useEffect, useRef, useState } from "react";
import SectionGridFilterCard from "containers/ListingCarPage/SectionGridFilterCard";
import useFetch from "hooks/useFetch";
import { SearchContext } from "context/searchContext";
import RentalCarSearchForm from "components/HeroSearchForm/(car-search-form)/RentalCarSearchForm";
import axiosInstance from "../utils/axiosInstance";
import { AuthContext } from "context/authContext";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
export interface ListingCarPageProps {
  className?: string;
}

const ShopPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  // const startDate
  const { user } = useContext(AuthContext);
  const { state } = useContext(SearchContext);
  const { startDate, endDate, pickUpLocation, returnLocation } = state;
  const { data, loading, error, reFetch } = useFetch(
    startDate && endDate
      ? `/api/v1/cars/available?from=${startDate.toISOString()}&to=${endDate.toISOString()}`
      : `/api/v1/cars/`
  );
  const location = useLocation();
  const searchState = location?.state;
  const [wishlists, setWishList] = useState([]);

  const displayedCarsRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = async () => {
    await reFetch(
      startDate && endDate
        ? `/api/v1/cars/available?from=${startDate.toISOString()}&to=${endDate.toISOString()}`
        : `/api/v1/cars?`
    );
    if (displayedCarsRef.current) {
      displayedCarsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchWishList = async () => {
      try {
        const res = await axiosInstance.get(
          `/api/v1/users/getcarid/${user._id}`
        );
        setWishList(res.data.carList);
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      fetchWishList();
    }
  }, [user]);

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className={`nc-ListingCarPage relative ${className}`}
      data-nc-id="ListingCarPage"
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <Helmet>
        <title>搜尋租車車輛 - 長勝租車 GoodwayPass Car</title>
      </Helmet>
      <div
        className={`nc-HeroSearchForm w-full mx-auto max-w-6xl md:py-5 lg:py-0 ${className}`}
      >
        {
          <RentalCarSearchForm
            classname="!bg-neutral-100 dark:text-neutral-700 dark:md:text-neutral-100 dark:!bg-neutral-800 md:!rounded-t-[40px] xl:!rounded-t-[49px] p-5 md:p-0"
            startDate={startDate}
            endDate={endDate}
            pickUpLocation={pickUpLocation}
            returnLocation={returnLocation}
            onFilterChange={handleFilterChange}
          />
        }
      </div>

      <div className="container relative pt-20" ref={displayedCarsRef}>
        {/* SECTION */}

        <SectionGridFilterCard
          className="pb-24 lg:pb-28"
          data={data}
          loading={loading}
          wishList={wishlists}
          searchState={searchState}
        />
      </div>
    </motion.div>
  );
};

export default ShopPage;
