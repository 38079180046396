import { FC } from "react";
import { BookingDataType } from "data/types";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { useTranslation } from "react-i18next";

export interface BookingCardProps {
  className?: string;
  data: BookingDataType;
  size?: "default" | "small";
}

const BookingCard: FC<BookingCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { t } = useTranslation("paydone");

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={data?.car?.featuredImage}
            alt={data?.car?.brand + " " + data?.car?.model}
          />
        </div>
        {data?.status && (
          <Badge
            name={
              data?.status == "Pending"
                ? t("STATUSPENDING", "Pending")
                : data?.status == "waitingPayment"
                ? t("PENDING", "Awaiting Payment")
                : data?.status == "Cancelled"
                ? t("STATUSCANCELLED", "Canceled")
                : data?.status == "Confirmed"
                ? t("STATUSCONFIRMED", "Confirmed")
                : data?.status
            }
            color={
              data?.status == "Pending"
                ? "yellow"
                : data?.status == "waitingPayment"
                ? "pink"
                : data?.status == "Cancelled"
                ? "red"
                : data?.status == "Confirmed"
                ? "green"
                : "gray"
            }
            className="absolute left-2 top-2"
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <div className="line-clamp-1 text-sm">
                {t("BOOKINGID", "訂單號碼")}: #
                {data?.bookingId && data?.bookingId.toString()}
              </div>
              <span className="line-clamp-1">
                {data?.car?.brand} {data?.car?.model}
              </span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <div className="">
              {data?.car?.isBoat
                ? t("common:BOATPICKUPTIME", "上船時間")
                : t("PICKUPDATE", "取車日期")}
              {" : "}
              {data?.pickUpDate &&
                new Date(data.pickUpDate).toLocaleDateString(
                  currentLanguage == "en" ? "en-US" : "zh-HK"
                )}{" "}
            </div>
            <div className="">
              {data?.car?.isBoat
                ? t("common:BOATRETURNTIME", "落船時間")
                : t("RETURNDATE", "還車日期")}
              {" : "}
              {data?.returnDate &&
                new Date(data.returnDate).toLocaleDateString(
                  currentLanguage == "en" ? "en-US" : "zh-HK"
                )}
            </div>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {t("TOTAL", "總共")}: ${data?.total && data?.total.toString()}
          </span>
          {/* <StartRating
            reviewCount={data?.reviewCount}
            point={data?.reviewStart}
          /> */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl  bg-white dark:bg-neutral-900  ${className}`}
      data-nc-id="CarCard"
    >
      <Link
        to={`/pay-done?bookingId=${data?.bookingId}&key=${data?.key}&account=true`}
        className="flex flex-col"
      >
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default BookingCard;
