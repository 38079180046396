"use client";

import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";

export interface RentalCarSearchFormProps {
  classname?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  pickUpLocation?: string | null;
  returnLocation?: string | null;
  onFilterChange?: any;
}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = ({
  classname = "",
  startDate,
  endDate,
  pickUpLocation,
  returnLocation,
  onFilterChange,
}) => {
  const [pickUp, setPickup] = useState<string>("");
  const [returnCar, setReturnCar] = useState<string>("");
  const [minDateDatepicker, setMinDateDatepicker] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1); // add one day to today's date
    return tomorrow;
  });

  useEffect(() => {
    if (pickUpLocation) setPickup(pickUpLocation);
  }, [pickUpLocation]);

  useEffect(() => {
    if (returnLocation) setReturnCar(returnLocation);
  }, [returnLocation]);

  const { t } = useTranslation("common");

  return (
    <form
      className={`w-full relative md:mt-8 md:rounded-[40px] xl:rounded-[49px] md:rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl md:bg-white dark:bg-neutral-800 ${classname}`}
    >
      <div
        className={`flex-wrap md:flex-nowrap relative flex flex-row text-sm md:text-md`}
      >
        <LocationInput
          placeHolder=""
          desc={t("PICKUPLOCATION", "取車地點") || "Pickup Location"}
          className=" rounded-lg md:rounded-none bg-white md:bg-transparent mb-[5px] md:mb-0 flex-none w-full md:w-[30%]  dark:border-slate-700"
          location={pickUpLocation}
          setLocation={(value: any) => setPickup(value)}
        />

        <div className="hidden md:flex self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <LocationInput
          placeHolder=""
          desc={t("RETURNLOCATION", "還車地點") || "Return Location"}
          className="rounded-lg md:rounded-none bg-white md:bg-transparent mb-[5px] md:mb-0 flex-none width-half w-full  md:w-[30%]  "
          divHideVerticalLineClass="-inset-x-0.5"
          location={returnLocation}
          setLocation={(value: any) => setReturnCar(value)}
        />

        <div className="hidden md:flex self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarDatesRangeInput
          className=" rounded-lg md:rounded-none w-full md:w-[40%] bg-white md:bg-transparent "
          updateStart={startDate}
          updateEnd={endDate}
          pickUpLocation={pickUp}
          returnLocation={returnCar}
          onFilterChange={onFilterChange}
          minDateDatepicker={minDateDatepicker}
          setMinDateDatepicker={setMinDateDatepicker}
        />
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
