import axios from "axios";
import Cookies from "js-cookie";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.code === "TOKEN_EXPIRED" && // Check for a specific error code or message related to token expiration
      !originalRequest._retry &&
      Cookies.get("refresh_token")
    ) {
      originalRequest._retry = true;
      try {
        const response = await axiosInstance.post("/api/v1/auth/refresh", {
          refresh_token: Cookies.get("refresh_token"),
        });
        Cookies.set("access_token", response.data.access_token, { expires: 1 });
        return axiosInstance(originalRequest);
      } catch (err) {
        throw err;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
