import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/car-rental",
    name: "Car List",
  },
  {
    id: ncNanoId(),
    href: "/yacht-rental",
    name: "Yacht",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/about",
  //   name: "About",
  // },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/unique_id_",
  //   name: "Five columns",
  //   type: "megaMenu",
  //   megaMenu: megaMenuDemo,
  // },
];
