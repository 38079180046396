import { AuthContext } from "context/authContext";
import ErrorPage from "pages/404";
import React, { useContext } from "react";
import { FC } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const { user, loading, error } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation("account");
  // if (!user) {
  //   // Redirect to login page if user is not logged in
  //   navigate("/login", {
  //     state: { alertMessage: "Please login to access this page." },
  //   });
  // }

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };
  return user ? (
    <motion.div
      className="nc-CommonLayoutProps bg-neutral-50 dark:bg-neutral-900"
      variants={sectionVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="border-b border-neutral-200 dark:border-neutral-700 pt-12 bg-white dark:bg-neutral-800">
        <div className="container">
          <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
            <NavLink
              to="/account"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("ACCOUNTINFO", { defaultValue: "帳戶資訊" })}
            </NavLink>
            <NavLink
              to="/account-upload"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("UPLOADDOCUMENT", { defaultValue: "上傳文件" })}
            </NavLink>
            <NavLink
              to="/account-bookings"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("MYBOOKINGS", { defaultValue: "我的預訂" })}
            </NavLink>
            <NavLink
              to="/account-savelists"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("SAVELISTS", { defaultValue: "儲存清單" })}
            </NavLink>
            <NavLink
              to="/account-password"
              className={({ isActive }) =>
                `block py-5 md:py-8 border-b-2 flex-shrink-0 ${
                  !isActive ? "border-transparent" : "border-primary-500"
                }`
              }
            >
              {t("CHANGEPASSWORD", { defaultValue: "更改密碼" })}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container pt-14 sm:pt-20 pb-24 lg:pb-32">{children}</div>
    </motion.div>
  ) : (
    <ErrorPage
      link="login"
      buttonText={t("BACKTOLOGIN", { defaultValue: "回到登入頁面" })}
      message={t("SIGNINVIEW", { defaultValue: "請登入以檢視您的個人資料" })}
    />
  );
};

export default CommonLayout;
