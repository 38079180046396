import { FC, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "context/authContext";
import axiosInstance from "../utils/axiosInstance";
import Cookies from "js-cookie";
import Alert from "components/Alert/alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import { CountrySelector } from "react-international-phone";
import "react-international-phone/style.css";
import Label from "components/Label/Label";
export interface PageSignUpProps {
  className?: string;
}

const RegisterPage: FC<PageSignUpProps> = ({ className = "" }) => {
  const { loading, error, dispatch } = useContext(AuthContext);
  const { t } = useTranslation("register");
  const [apiLoading, setApiLoading] = useState(false);
  const [countryPhone, setCountryPhone] = useState("hk");
  const [countryCode, setCountryCode] = useState("852");
  const navigate = useNavigate();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  const validationSchema = Yup.object({
    engName: Yup.string().required(
      `${t("ENGNAMEREQUIRED", { defaultValue: "請輸入名字 " })}`
    ),
    email: Yup.string()
      .email(`${t("INVALIDEMAIL", { defaultValue: "無效的電子郵件格式 " })}`)
      .required(`${t("EMAILREQUIRED", { defaultValue: "請輸入電子郵件 " })}`),
    confirmEmail: Yup.string()
      .oneOf(
        [Yup.ref("email"), undefined],
        `${t("EMAILMATCH", { defaultValue: "電子郵件地址不匹配 " })}`
      )
      .required(
        `${t("CONFIRMEMAILREQUIRED", { defaultValue: "請輸入確認電子郵件" })}`
      ),
    phone: Yup.string().required(
      `${t("PHONEREQUIRED", { defaultValue: "請輸入電話號碼" })}`
    ),
    password: Yup.string()
      .min(
        6,
        `${t("PASSWORDTOOSHORT", { defaultValue: "密碼必須至少為6個字符" })}`
      )
      .required(`${t("REQUIRED", { defaultValue: "必填" })}`),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        `${t("PASSWORDMATCH", { defaultValue: "密碼不匹配 " })}`
      )
      .required(`${t("REQUIRED", { defaultValue: "必填" })}`),
  });

  const formik = useFormik({
    initialValues: {
      engName: "",
      email: "",
      confirmEmail: "",
      countryPhone: "",
      countryCode: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setApiLoading(true);
      try {
        const res = await axiosInstance.post(
          "/api/v1/auth/register",
          Object.assign({
            ...values,
            username: values?.email,
            role: "customer",
          })
        );
        if (countryPhone) {
          values.countryPhone = countryPhone;
        }

        if (countryCode) {
          values.countryCode = countryCode;
        }

        // If registration is successful, start the login process
        dispatch({ type: "LOGIN_START" });

        try {
          const loginRes = await axiosInstance.post("/api/v1/auth/login", {
            usernameOrEmail: values?.email,
            password: values.password,
          });
          dispatch({ type: "LOGIN_SUCCESS", payload: loginRes.data.details });
          // Cookies.set("access_token", res.data.access_token);
          // Cookies.set("refresh_token", res.data.refresh_token);
          setApiLoading(false);
          setAlert({
            show: true,
            message: `${t("ACCOUNTCREATED", { defaultValue: "帳戶已創建" })}!`,
            type: "success",
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } catch (err: any) {
          setApiLoading(false);
          dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
          setAlert({
            show: true,
            message: `${t("LOGINFAILED", { defaultValue: "註冊後登錄失敗" })}.`,
            type: "error",
          });
        }
      } catch (err: any) {
        setApiLoading(false);
        // Handle registration failure separately
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
  });

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0 && formik.isSubmitting) {
      formik.setSubmitting(false);
      const firstErrorKey = errorKeys[0];
      const firstErrorElement =
        document.getElementsByName(firstErrorKey)[0] ||
        document.getElementsByClassName(firstErrorKey)[0];
      if (firstErrorElement) {
        const headerHeight = 120;
        const rect = firstErrorElement.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const topPosition = rect.top + scrollTop - headerHeight;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  const firstErrorRef = useRef(null);

  const sectionVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {" "}
      <motion.div
        className={`nc-PageSignUp  ${className}`}
        data-nc-id="PageSignUp"
        variants={sectionVariants}
        initial="hidden"
        animate="visible"
      >
        <Helmet>
          <title>
            {t("REGISTER", { defaultValue: "註冊 " })} | 長勝租車 GoodwayPass
            Car Rental
          </title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {t("SIGNUP", { defaultValue: "註冊 " })}
          </h2>
          <div className="max-w-md mx-auto space-y-6 ">
            {/* FORM */}
            <form
              onSubmit={formik.handleSubmit}
              className="grid grid-cols-1 gap-6"
            >
              <label
                className="block"
                ref={formik.errors.engName ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("ENGNAME", { defaultValue: "名字 " })}
                </span>
                <Input
                  id="engName"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.engName}
                />
                {formik.touched.engName && formik.errors.engName ? (
                  <p className="text-red-600">
                    {formik.errors.engName.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.email ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("EMAIL", { defaultValue: "電子郵件 " })}
                </span>
                <Input
                  id="email"
                  type="email"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-600">
                    {formik.errors.email.toString()}
                  </p>
                ) : null}
              </label>
              <label
                className="block"
                ref={formik.errors.confirmEmail ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("CONFIRMEMAIL", { defaultValue: "確認電子郵件 " })}
                </span>
                <Input
                  id="confirmEmail"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmEmail}
                />
                {formik.touched.confirmEmail && formik.errors.confirmEmail ? (
                  <p className="text-red-600">
                    {formik.errors.confirmEmail.toString()}
                  </p>
                ) : null}
              </label>
              <div className="flex">
                <div
                  className="space-y-1"
                  ref={formik.errors.phone ? firstErrorRef : null}
                >
                  <Label>{t("PHONE", "電話")}</Label>
                  <CountrySelector
                    buttonClassName="rounded-l-xl !py-4 !h-[44px] !border-r-0"
                    selectedCountry={countryPhone || ""}
                    onSelect={({ iso2, dialCode }) => {
                      setCountryPhone(iso2);
                      setCountryCode(dialCode);
                    }}
                  />
                </div>
                <div
                  className="space-y-1 !grow"
                  ref={formik.errors.phone ? firstErrorRef : null}
                >
                  <Input
                    type="tel"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={countryPhone === "hk" ? 8 : 20}
                    className="!rounded-r-xl !rounded-l-none mt-[28px] !grow !border-l-0"
                    // ref={phoneRef}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p className="text-red-600">
                      {formik.errors.phone.toString()}
                    </p>
                  ) : null}
                </div>
              </div>
              <label
                className="block"
                ref={formik.errors.password ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("PASSWORD", { defaultValue: "密碼 " })}
                </span>
                <Input
                  id="password"
                  type="password"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-600">
                    {formik.errors.password.toString()}
                  </p>
                ) : null}
              </label>
              <label
                className="block"
                ref={formik.errors.confirmPassword ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("CONFIRMPASSWORD", { defaultValue: "確認密碼" })}
                </span>
                <Input
                  id="confirmPassword"
                  type="password"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <p className="text-red-600">
                    {formik.errors.confirmPassword.toString()}
                  </p>
                ) : null}
              </label>

              <ButtonPrimary type="submit" disabled={apiLoading}>
                {t("CONTINUE", { defaultValue: "繼續" })}
                {apiLoading ? (
                  <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                ) : null}
              </ButtonPrimary>
              {alert.show && (
                <Alert type={alert.type} message={alert.message} className="" />
              )}
            </form>

            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              {t("ALREADYACCOUNT", { defaultValue: "已經有一個帳戶 " })}? {` `}
              <Link to="/login">{t("SIGNIN", { defaultValue: "登入 " })}</Link>
            </span>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default RegisterPage;
