import { createContext, useReducer, Dispatch, ReactNode } from "react";

// State interface
interface State {
  startDate: Date | null;
  endDate: Date | null;
  pickUpLocation: string | null;
  returnLocation: string | null;
  productId: string | null;
  isBoat: boolean | null;
}

// Action interfaces
interface NewSearchAction {
  type: "NEW_SEARCH";
  payload: Partial<State>;
}

interface ResetSearchAction {
  type: "RESET_SEARCH";
}

interface AddProductAction {
  type: "ADD_PRODUCTID";
  payload: Partial<State>;
}

type ActionTypes = NewSearchAction | ResetSearchAction | AddProductAction;

// Initial state
const INITIAL_STATE: State = {
  startDate: null,
  endDate: null,
  pickUpLocation: null,
  returnLocation: null,
  productId: null,
  isBoat: false,
};

// Reducer function
const searchReducer = (state: State, action: ActionTypes): State => {
  let newState;
  switch (action.type) {
    case "NEW_SEARCH":
      newState = { ...state, ...action.payload };
      return newState;
    case "RESET_SEARCH":
      return INITIAL_STATE;
    case "ADD_PRODUCTID":
      newState = { ...state, ...action.payload };
      localStorage.setItem("selectedCarState", JSON.stringify(newState));
      return newState;
    default:
      return state;
  }
};

// Context
export const SearchContext = createContext<{
  state: State;
  dispatch: Dispatch<ActionTypes>;
}>({
  state: {
    startDate: null,
    endDate: null,
    pickUpLocation: null,
    returnLocation: null,
    productId: null,
    isBoat: false,
  },
  dispatch: () => {},
});

// Provider component
interface SearchContextProviderProps {
  children: ReactNode;
}

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const [state, dispatch] = useReducer(searchReducer, INITIAL_STATE);

  return (
    <SearchContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
