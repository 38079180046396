import { FC, useContext, useEffect, useRef, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import { AuthContext } from "context/authContext";
import axiosInstance from "../../utils/axiosInstance";
import Alert from "components/Alert/alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon/LoadingIcon";
import Textarea from "shared/Textarea/Textarea";
import { CountrySelector } from "react-international-phone";
import Label from "components/Label/Label";
import "react-international-phone/style.css";
export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { user, loadingUserData, initialLoading, dispatch } =
    useContext(AuthContext);
  const [countryPhone, setCountryPhone] = useState("hk");
  const [countryCode, setCountryCode] = useState("852");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [apiLoading, setApiLoading] = useState(false);
  const { t } = useTranslation("account");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "info",
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        engName: user?.engName,
        email: user?.email,
        confirmEmail: user?.email,
        phone: user?.phone,
        gender: user?.gender,
        address: user?.address,
        chineseName: user?.chineseName,
        dateOfBirth: user?.dateOfBirth,
        countryCode: user?.countryCode,
        countryPhone: user?.countryPhone,
        idType: user?.idType,
        idNo: user?.idNo,
        language: user?.language,
        licenseExpiredDate: user?.licenseExpiredDate,
        emergencyNo: user?.emergencyNo,
        emergencyContact: user?.emergencyContact,
        nationality: user?.nationality,
        pLicense: user?.pLicense || false,
      });
    }
  }, [user, loadingUserData, initialLoading]);

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ show: false, message: "", type: "" });
      }, 3000);
    }
  }, [alert.show]);

  const validationSchema = Yup.object({
    engName: Yup.string().required(
      `${t("NAMEREQUIRED", { defaultValue: "Name is required" })}`
    ),
    email: Yup.string()
      .email(
        `${t("INVALIDEMAILFORMAT", { defaultValue: "Invalid email format" })}`
      )
      .required(`${t("EMAILREQUIRED", { defaultValue: "Email is required" })}`),
    phone: Yup.string().required(
      `${t("PHONEREQUIRED", { defaultValue: "Phone is required" })}`
    ),
    address: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      engName: user ? user.engName : "",
      email: user ? user.email : "",
      phone: user ? user.phone : "",
      countryCode: user ? user.countryCode : "",
      countryPhone: user ? user.countryPhone : "",
      gender: user ? user.gender : "",
      address: user ? user.address : "",
      chineseName: user ? user.chineseName : "",
      dateOfBirth: user ? user.dateOfBirth : "",
      idType: user ? user.idType : "",
      idNo: user ? user.idNo : "",
      language: user ? user.language : "",
      nationality: user ? user.nationality : "",
      licenseExpiredDate: user ? user.licenseExpiredDate : "",
      emergencyNo: user ? user.emergencyNo : "",
      emergencyContact: user ? user.emergencyContact : "",
      pLicense: user ? user.pLicense : "",
    },
    validationSchema,
    onSubmit: async (values: {
      engName: string;
      email: string;
      phone: string;
      countryCode: string;
      countryPhone: string;
      gender: string;
      address: string;
      chineseName: string;
      dateOfBirth: string;
      idType: string;
      idNo: string;
      language: string;
      licenseExpiredDate: string;
      emergencyNo: string;
      emergencyContact: string;
      pLicense: string;
      nationality: string;
      [key: string]: any;
    }) => {
      setApiLoading(true);
      if (countryPhone) {
        values.countryPhone = countryPhone;
      }

      if (countryCode) {
        values.countryCode = countryCode;
      }
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        const res = await axiosInstance.put(
          `/api/v1/users/${user._id}`,
          formData
        );
        setApiLoading(false);
        setAlert({
          show: true,
          message: `${t("ACCOUNTUPDATED", {
            defaultValue: "Account was updated!",
          })}`,
          type: "success",
        });
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
      } catch (err: any) {
        setApiLoading(false);
        setAlert({
          show: true,
          message: err?.response?.data?.message,
          type: "error",
        });
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    const errorKeys = Object.keys(formik.errors);
    if (errorKeys.length > 0 && formik.isSubmitting) {
      formik.setSubmitting(false);
      const firstErrorKey = errorKeys[0];
      const firstErrorElement =
        document.getElementsByName(firstErrorKey)[0] ||
        document.getElementsByClassName(firstErrorKey)[0];
      if (firstErrorElement) {
        const headerHeight = 120;
        const rect = firstErrorElement.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const topPosition = rect.top + scrollTop - headerHeight;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
      }
    }
  }, [formik.errors, formik.isSubmitting]);

  const firstErrorRef = useRef(null);

  // const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setSelectedImage(e.target.files[0]);
  //     setSelectedImageUrl(URL.createObjectURL(e.target.files[0]));
  //   }
  // };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>我的帳號 - 長勝租車 GoodwayPass Car Rental</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">
            {t("ACCOUNTINFORMATION", "帳戶資訊")}
          </h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col md:flex-row"
          >
            <div className="flex-shrink-0 flex items-start">
              {/* <div className="relative rounded-full overflow-hidden flex">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={selectedImageUrl || user.profileImage}
                  userName={
                    user.lastName ||
                    user?.engName ||
                    user?.userName ||
                    user?.email ||
                    ""
                  }
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div> */}
              <div className="relative rounded-full overflow-hidden flex group">
                <Avatar
                  sizeClass="w-32 h-32"
                  imgUrl={selectedImageUrl || user.profileImage}
                  userName={
                    user?.engName || user?.userName || user?.email || ""
                  }
                />
                {/* <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer opacity-0 group-hover:opacity-100 transition">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
         
                  </svg>

                  <span className="mt-1 text-xs">Change Image</span>
                </div> */}

                {/* <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleImageChange}
                /> */}
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <label
                className="block"
                ref={formik.errors.engName ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("ENGNAME", { defaultValue: "英文名稱" })}
                </span>
                <Input
                  id="engName"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.engName}
                />
                {formik.touched.engName && formik.errors.engName ? (
                  <p className="text-red-600">
                    {formik.errors.engName.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.chineseName ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("CHINESENAME", "中文名稱")}
                </span>
                <Input
                  id="chineseName"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.chineseName}
                />
                {formik.touched.chineseName && formik.errors.chineseName ? (
                  <p className="text-red-600">
                    {formik.errors.chineseName.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.gender ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("GENDER", { defaultValue: "性別" })}
                </span>
                <Select
                  id="gender"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gender}
                >
                  <option value=""></option>
                  <option value="male">
                    {t("MALE", { defaultValue: "男" })}
                  </option>
                  <option value="female">
                    {t("FEMALE", { defaultValue: "女" })}
                  </option>
                </Select>
                {formik.touched.gender && formik.errors.gender ? (
                  <p className="text-red-600">
                    {formik.errors.gender.toString()}
                  </p>
                ) : null}
              </label>
              <label
                className="block"
                ref={formik.errors.email ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("EMAIL", { defaultValue: "電子郵件" })}
                </span>
                <Input
                  id="email"
                  type="email"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-600">
                    {formik.errors.email.toString()}
                  </p>
                ) : null}
              </label>

              {/* <label
                className="block"
                ref={formik.errors.phone ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("PHONE", { defaultValue: "電話" })}
                </span>
                <Input
                  id="phone"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p className="text-red-600">
                    {formik.errors.phone.toString()}
                  </p>
                ) : null}
              </label> */}
              <div className="flex">
                <div
                  className="space-y-1"
                  ref={formik.errors.phone ? firstErrorRef : null}
                >
                  <Label>{t("PHONE", "電話")}</Label>
                  <CountrySelector
                    buttonClassName="rounded-l-xl !py-4 !h-[44px] !border-r-0"
                    selectedCountry={countryPhone || ""}
                    onSelect={({ iso2, dialCode }) => {
                      setCountryPhone(iso2);
                      setCountryCode(dialCode);
                    }}
                  />
                </div>
                <div
                  className="space-y-1 !grow"
                  ref={formik.errors.phone ? firstErrorRef : null}
                >
                  <Input
                    type="tel"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={countryPhone === "hk" ? 8 : 20}
                    className="!rounded-r-xl !rounded-l-none mt-[28px] !grow !border-l-0"
                    // ref={phoneRef}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p className="text-red-600">
                      {formik.errors.phone.toString()}
                    </p>
                  ) : null}
                </div>
              </div>
              {/* <label
                className="timepick mr-2 relative bg-white"
                ref={formik.errors.dateOfBirth ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  Date of Birth
                </span>
                <DatePicker
                  id="dateOfBirth"
                  selected={
                    formik.values.dateOfBirth
                      ? new Date(formik.values.dateOfBirth) // Convert string to Date object
                      : null
                  }
                  onChange={(date) =>
                    formik.setFieldValue(
                      "dateOfBirth",
                      date instanceof Date
                        ? date.toISOString().split("T")[0]
                        : ""
                    )
                  }
                  showPopperArrow={false}
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderTwoMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                  )}
                  focusSelectedMonth={true}
                  showYearDropdown
                  monthsShown={2}
                  selectsRange
                  dateFormat="yyyy-MM-dd"
                  className="!bg-white text-center py-2 flex-1 cursor-pointer mt-1"
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                  <p className="text-red-600">
                    {formik.errors.dateOfBirth.toString()}
                  </p>
                ) : null}
              </label> */}

              <label
                className="block"
                ref={formik.errors.dateOfBirth ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("DATEOFBIRTH", { defaultValue: "出生日期" })}
                </span>
                <Input
                  id="dateOfBirth"
                  type="date"
                  defaultValue=""
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfBirth}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                  <p className="text-red-600">
                    {formik.errors.dateOfBirth.toString()}
                  </p>
                ) : null}
              </label>
              <label
                className="block"
                ref={formik.errors.address ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("ADDRESS", { defaultValue: "地址" })}
                </span>
                <Textarea
                  id="address"
                  // type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address ? (
                  <p className="text-red-600">
                    {formik.errors.address.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.idNo ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("PASSPORTNO", { defaultValue: "身分證 / 護照號碼" })}
                </span>
                <div className="flex">
                  <Select
                    id="idType"
                    className="mt-1 w-4/12"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idType}
                  >
                    <option value=""></option>
                    <option value="id">
                      {t("ID", { defaultValue: "ID" })}
                    </option>
                    <option value="passport">
                      {t("PASSPORT", { defaultValue: "Passport" })}
                    </option>
                  </Select>
                  <Input
                    id="idNo"
                    type="text"
                    className="mt-1 w-6/12"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idNo}
                  />
                </div>

                {formik.touched.idNo && formik.errors.idNo ? (
                  <p className="text-red-600">
                    {formik.errors.idNo.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.licenseExpiredDate ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("LICENSEEXPIREDDATE", {
                    defaultValue: "駕照執照過期日期",
                  })}
                </span>
                <Input
                  id="licenseExpiredDate"
                  type="date"
                  defaultValue=""
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.licenseExpiredDate}
                />
                {formik.touched.licenseExpiredDate &&
                formik.errors.licenseExpiredDate ? (
                  <p className="text-red-600">
                    {formik.errors.licenseExpiredDate.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.language ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("LANGUAGE", { defaultValue: "語言" })}
                </span>
                <Select
                  id="language"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.language}
                >
                  <option value=""></option>
                  <option value="cantonese">
                    {t("CANTONESE", {
                      defaultValue: "Cantonese",
                    })}
                  </option>
                  <option value="mandarin">
                    {t("MADARINCHINESE", {
                      defaultValue: "Mandarin Chinese",
                    })}
                  </option>
                  <option value="english">
                    {t("ENGLISH", {
                      defaultValue: "English",
                    })}
                  </option>
                  <option value="spanish">
                    {t("SPANISH", {
                      defaultValue: "Spanish",
                    })}
                  </option>
                  <option value="french">
                    {t("FRENCH", {
                      defaultValue: "French",
                    })}
                  </option>
                  <option value="german">
                    {t("GERMAN", {
                      defaultValue: "German",
                    })}
                  </option>
                  <option value="japanese">
                    {t("JAPANESE", {
                      defaultValue: "Japanese",
                    })}
                  </option>
                  <option value="portuguese">
                    {t("PORTUGUESE", {
                      defaultValue: "Portuguese",
                    })}
                  </option>
                  <option value="russian">
                    {t("RUSSIAN", {
                      defaultValue: "Russian",
                    })}
                  </option>
                </Select>
                {formik.touched.language && formik.errors.language ? (
                  <p className="text-red-600">
                    {formik.errors.language.toString()}
                  </p>
                ) : null}
              </label>
              <label
                className="block"
                ref={formik.errors.nationality ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("NATIONALITY", { defaultValue: "國籍" })}
                </span>
                <Select
                  id="nationality"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.nationality}
                >
                  <option value=""></option>
                  <option value="chinese">
                    {t("CHINESE", {
                      defaultValue: "Chinese",
                    })}
                  </option>
                  <option value="japanese">
                    {t("JAPANESEE", {
                      defaultValue: "Japanese",
                    })}
                  </option>
                  <option value="american">
                    {t("AMERICAN", {
                      defaultValue: "American",
                    })}
                  </option>
                  <option value="british">
                    {t("BRITISH", {
                      defaultValue: "British",
                    })}
                  </option>
                  <option value="canadian">
                    {t("CANADIAN", {
                      defaultValue: "Canadian",
                    })}
                  </option>
                  <option value="australian">
                    {t("AUSTRALIAN", {
                      defaultValue: "Australian",
                    })}
                  </option>
                  <option value="french">
                    {t("FRENCH", {
                      defaultValue: "French",
                    })}
                  </option>
                  <option value="german">
                    {t("GERMAN", {
                      defaultValue: "German",
                    })}
                  </option>
                  <option value="russian">
                    {t("RUSSIAN", {
                      defaultValue: "Russian",
                    })}
                  </option>
                  <option value="mexican">
                    {t("MEXICAN", {
                      defaultValue: "Mexican",
                    })}
                  </option>
                  <option value="brazilian">
                    {t("BRAZILIAN", {
                      defaultValue: "Brazilian",
                    })}
                  </option>
                  <option value="italian">
                    {t("ITALIAN", {
                      defaultValue: "Italian",
                    })}
                  </option>
                  <option value="spanish">
                    {t("SPANISH", {
                      defaultValue: "Spanish",
                    })}
                  </option>
                  <option value="indian">
                    {t("ITALIAN", {
                      defaultValue: "Indian",
                    })}
                  </option>
                  <option value="southafrican">
                    {t("SOUTHAFRICAN", {
                      defaultValue: "South African",
                    })}
                  </option>
                  <option value="nigerian">
                    {t("NIGERIAN", {
                      defaultValue: "Nigerian",
                    })}
                  </option>
                  <option value="egyptian">
                    {t("EGYPTIAN", {
                      defaultValue: "Egyptian",
                    })}
                  </option>
                  <option value="saudiarabian">
                    {t("SAUDIARABIAN", {
                      defaultValue: "Saudi Arabian",
                    })}
                  </option>
                  <option value="argentine">
                    {t("ARGENTINE", {
                      defaultValue: "Argentine",
                    })}
                  </option>
                  <option value="swedish">
                    {t("SWEDISH", {
                      defaultValue: "Swedish",
                    })}
                  </option>
                </Select>
                {formik.touched.nationality && formik.errors.nationality ? (
                  <p className="text-red-600">
                    {formik.errors.nationality.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.emergencyNo ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("EMERGENCYNO", { defaultValue: "緊急聯絡電話" })}
                </span>
                <Input
                  id="emergencyNo"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyNo}
                />
                {formik.touched.emergencyNo && formik.errors.emergencyNo ? (
                  <p className="text-red-600">
                    {formik.errors.emergencyNo.toString()}
                  </p>
                ) : null}
              </label>

              <label
                className="block"
                ref={formik.errors.emergencyContact ? firstErrorRef : null}
              >
                <span className="text-neutral-800 dark:text-neutral-200">
                  {t("EMEERGENCYCONTACT", {
                    defaultValue: "緊急聯絡人",
                  })}
                </span>
                <Input
                  id="emergencyContact"
                  type="text"
                  className="mt-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyContact}
                />
                {formik.touched.emergencyContact &&
                formik.errors.emergencyContact ? (
                  <p className="text-red-600">
                    {formik.errors.emergencyContact.toString()}
                  </p>
                ) : null}
              </label>

              <div className="pt-2">
                <ButtonPrimary type="submit" disabled={apiLoading}>
                  {t("UPDATEINFO", { defaultValue: "更新資訊" })}
                  {apiLoading ? (
                    <LoadingIcon className="w-4 h-4 ml-2 text-white" />
                  ) : null}
                </ButtonPrimary>
              </div>
              {alert.show && (
                <Alert type={alert.type} message={alert.message} className="" />
              )}
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
