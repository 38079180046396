import React from "react";
import { Link } from "react-router-dom";
import logoImg from "../../images/goodwaypass.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  className = "w-[200px] lg:w-40",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {img && <img className={`block lg:max-h-12 `} src={img} alt="Logo" />}
    </Link>
  );
};

export default Logo;
