import React, { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Heading from "shared/Heading/Heading";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";
import youtube from "images/youtube/youtube-6.jpg";
import { useTranslation } from "react-i18next";
export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
  videos?: VideoType[];
}

export interface VideoType {
  id: string;
  title: string;
  thumbnail: string;
}

export interface SectionVideosProps {
  videos?: VideoType[];
  className?: string;
}

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = "" }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(0);
  const { t } = useTranslation("boat");
  const data: SectionHowItWorkProps["data"] = [
    {
      id: 1,
      img: HIW1img,
      title: t("BOATDATA1", "意大利超豪華品牌"),
      desc: t("BOATDESC1", "集簡約及豪華於一身，設計充滿活力及質感"),
    },
    {
      id: 2,
      img: HIW2img,
      title: t("BOATDATA2", "朋友一家人聚會"),
      desc: t("BOATDESC2", "帶你去西貢、南區和陽光玩遊戲或者維港夜遊"),
    },
    {
      id: 3,
      img: HIW3img,
      title: t("BOATDATA3", "設施齊全、上落地點任你選擇"),
      desc: t(
        "BOATDESC3",
        "泳池、浮條、食用冰免費提供，上落地點可選觀塘、中環、尖沙咀"
      ),
    },
  ];

  const videos: VideoType[] = [
    {
      id: "CedtCKIU-fE",
      title: t("BOATVIDEO", "長勝推出遊艇租賃啦"),
      thumbnail: youtube,
    },
  ];

  const renderMainVideo = () => {
    const video: VideoType = videos[currentVideo];
    return (
      <div
        className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800 rounded-3xl overflow-hidden border-4 border-white dark:border-neutral-900 sm:rounded-[50px] sm:border-[10px] will-change-transform"
        title={video.title}
      >
        {isPlay ? (
          <iframe
            src={`https://www.youtube.com/embed/${video.id}?autoplay=1`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <>
            <div
              onClick={() => setIsPlay(true)}
              className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
            >
              <NcPlayIcon />
            </div>
            <NcImage
              containerClassName="absolute inset-0 "
              className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
              src={video.thumbnail}
              title={video.title}
              alt={video.title}
            />
          </>
        )}
      </div>
    );
  };
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc={t("BOATDESC", "享受愉快的海上生活")}>
        {t("BOATTITLE", "歐洲豪華遊艇")}
      </Heading>
      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row mt-16 max-w-[800px] mx-auto">
        <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl bg-primary-100 bg-opacity-40 z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-1/2 dark:bg-neutral-800 dark:bg-opacity-40"></div>
        <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6 ">
          {renderMainVideo()}
        </div>
      </div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt="Rental Step"
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                  alt={item.title}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                  alt={item.title}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
                alt={item.title}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
