import carcheck from "images/carcheck.jpg";
import RepairImage from "images/carrepairhk.jpg";
import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism, {
  Bg2Glassmorphism,
} from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./RepairPage/SectionHero";
import SectionPaint from "./RepairPage/SectionPaint";
import SectionMech from "./RepairPage/SectionMech";
// import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageRepair: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>汽車維修服務 - 長勝租車 GoodwayPass Car Rental </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <Bg2Glassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          repair={true}
          rightImg={RepairImage}
          heading="汽車維修服務"
          btnText="免費報價"
          subHeading={
            <div>
              你嘅首選汽車維修專家，確保您的愛車性能與安全，體驗專業的一站式服務！令你嘅愛車始終保持最佳狀態！
              <br />
              <br />
              🔍 愛車性能下降？可能係時候換機油同雞髀（搖臂 Control Arm）喇！🔍
              <br />
              🚗 💨駕駛時聽到 「吱吱聲」？ 🤔🤔 加油時感到「疾疾吓」？
              <br />
              呢啲都可能係你嘅車需要維修嘅信號： 🛢️- 機油：
              新鮮嘅機油能保持引擎內部潤滑，有效減少磨損，延長引擎壽命。如果加速時感到唔夠順，可能係時候要換機油喇。
              🍗- 雞髀（搖臂 Control
              <br />
              Arm）：確保懸掛系統嘅精準運作，提高操控穩定性，去除多餘嘅噪音，提升駕駛嘅舒適感同安全性。
              🛠️ 點解揀我哋？
              <br />
              👨🏻‍🔧專業技師：我哋嘅技師團隊有多年維修經驗，對各種車型都瞭如指掌，提供可靠服務。
              <br />
              ⏱️效率服務：承諾高效率完成維修，減少您嘅等待時間，快速交還您嘅愛車。
              <br />
              💵透明定價 優質保證：提供合理嘅價格同明確報價，品質上乘。
              <br />
              📍位置方便：交通便利，易於到達。
              <br />
              九龍長沙灣興華街38號（順寧道461側入）
            </div>
          }
        />

        <SectionPaint />
        <SectionMech />
        <div className={"w-[500px] max-w-full mx-auto text-center"}>
          <img className={""} src={carcheck} alt="" />
        </div>

        {/* <SectionStatistic /> */}

        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
};

export default PageRepair;
